<template>
 <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}</p>
                        <p v-b-modal.modal-3 variant="link" class="mb-0"><u>1688구매내역 다운로드 방법</u></p>

                            <b-modal id="modal-3" size="xl" title="1688구매내역 다운로드 방법" ok-title="Save Changes" cancel-title="Close">
                                <p><a href="https://www.1688.com" target="_blank"><u>1688</u></a>에 접속해서 아래의 순서로 엑셀을 다운받은 후 업로드해주세요</p>
                                <img  style="max-width:100%;" :src="require('../../../assets/images/small/1688주문서다운로드.png')">
                            </b-modal>

                    </div>

                    <div class="d-flex flex-column">
                        <button type="button" v-b-modal.modal class="btn btn-primary add-list" @click="focusrate"><i class="las la-plus mr-3"></i>{{linktext}}</button>
                        <button type="button" class="btn btn-success add-list mt-2" @click="openfileinput">
                            {{uploadtext}}</button>
                        <input ref="pdballupload" type="file" @change="uploadallfile" class="custom-file-input d-none">
                    </div>

                    <b-modal ref="bvModal" id="modal" size="xl" title="1688 구매내역 업로드" ok-title="매칭저장" cancel-title="취소" @ok="handleOk" @cancel="cancel">
                        <div class="col-md-12">
                            <p>1688 구매내역을 업로드 해주세요. &nbsp;&nbsp;적용환율 : <span><input style="width:80px" type="number" ref="rate" placeholder="적용환율" v-model="환율">원/위안</span></p>
                            <!-- <p>*구매단가는 한국가격 기준 1개당 구매가격입니다. (*구매단가 = (구매가격+배송가격+할인가격x환율)/주문수량)</p> -->
                            <p>아래 매칭일자가 정확하지 않은 경우, 잘못 매칭될 수 있습니다. 확인 후에 파일을 업로드 해주세요.</p>

                        </div>
                        <div class="col-md-12">
                            <h5>[1688 구매내역 - 구매요청매핑 업로드]</h5>
                        </div>
                        <div class="row card ml-2 mr-2">
                        <div class="col-md-12 mt-3">
                            <p><b>[매칭될 구매요청일자] - 오래된 순서대로 먼저 매칭됨</b></p>
                                <div class="text-center" v-if="showgetreqdate">
                                    <img style="width:5%;" :src="require('../../../assets/images/small/loading.gif')">
                                </div>
                                <div class="mb-0" v-for="a,i in reqdates" :key="i"><b><span style="color:blue">{{a.date}}</span></b>
                                    <div class="checkbox d-inline-block ml-2">
                                        <input type="checkbox" class="checkbox-input" v-model="a.checked">
                                        <label for="checkbox1111" class="mb-0"></label>
                                    </div>
                                </div>
                        </div>

                        <!-- <div class="col-md-12">
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadio7" name="customRadio-2" class="custom-control-input" v-model="selectdate1" value="today" @change="changedate1()">
                                <label class="custom-control-label" for="customRadio7">오늘</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadio8" name="customRadio-2" class="custom-control-input" v-model="selectdate1" value="week" @change="changedate1()">
                                <label class="custom-control-label" for="customRadio8">최근7일</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadio9" name="customRadio-2" class="custom-control-input" v-model="selectdate1" value="month" @change="changedate1()">
                                <label class="custom-control-label" for="customRadio9">최근1개월</label>
                            </div>
                            <div class="custom-control custom-switch custom-control-inline">
                                <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="checksstock">
                                <label class="custom-control-label" for="customSwitch2">사입제품 포함 발주서만 보기</label>
                            </div>
                        </div> -->
                        <!-- <div class="col-md-12 mt-2">
                            <b-input-group>
                                <select class="custom-select col-md-2" style="height:40px" v-model="selectoption1">
                                    <option>구매요청일</option>
                                </select>
                                <b-form-datepicker id="example-datepicker1" size="sm" v-model="startdate1" class="mb-2"></b-form-datepicker>
                                <p>~</p>
                                <b-form-datepicker id="example-datepicker2" size="sm" v-model="enddate1" class="mb-2"></b-form-datepicker>

                            </b-input-group>
                        </div> -->

                            <div class="col-md-12" v-if="!showgetreqdate">
                            <div class="form-group">
                                <div class="custom-file">
                                <input ref="pdbupload" type="file" @click="showspinner = true" @change="uploadfile" class="custom-file-input" id="inputGroupFile02">
                                <label class="custom-file-label" for="inputGroupFile02">{{uploadstatus}}</label>
                                </div>
                            </div>
                        </div>
                        </div>


                        <div class="col-md-12" v-if="showspinner">
                            <div class="text-center">
                                <img style="width:5%;" :src="require('../../../assets/images/small/loading.gif')">
                            </div>
                        </div>

                        <div class="col-lg-12 mt-5" v-if="showmatchlist">
                            <div class="table-responsive rounded mb-3">
                                <tableList>
                                    <template v-slot:header >
                                        <tr class="ligth ligth-data">
                                            <!-- <th>
                                                <div class="checkbox d-inline-block">
                                                    <input type="checkbox" class="checkbox-input" v-model="checkbox1">
                                                    <label for="checkbox1" class="mb-0"></label>
                                                </div>
                                            </th> -->
                                            <th>상품매칭상태</th>
                                            <th>상품매칭</th>
                                            <th>요청매칭상태</th>
                                            <th>요청매칭</th>
                                            <th>상품정보</th>
                                            <th>주문수량</th>
                                            <th>판매처</th>
                                            <th>상품링크</th>
                                            <th>옵션1</th>
                                            <th>옵션2</th>
                                            <th>주문번호</th>
                                            <th style="">삭제</th>
                                        </tr>
                                    </template>
                                    <template v-slot:body >
                                        <tr v-for="(match,index) in matchlists" :key="index" >
                                            <!-- <td>
                                                <div class="checkbox d-inline-block">
                                                    <input type="checkbox" class="checkbox-input" id="checkbox2">
                                                    <label for="checkbox2" class="mb-0"></label>
                                                </div>
                                            </td> -->
                                            <td><div :class="matchclass(match.매칭상태)">{{match.매칭상태}}</div></td>
                                            <td>
                                                <b-dropdown class="my-class" size="xl" dropright variant="link" toggle-class="text-decoration-none" no-caret v-if="match.상품매칭[0] == '직접선택'">
                                                    <template #button-content>
                                                    &#x1f50d;<span class="sr-only">Search</span>
                                                    </template>
                                                    <b-dropdown-form href="#" v-for="a,i in match.상품매칭" :key="i" >

                                                        <p>상품DB에 없는 구매링크입니다. 상품을 검색해서 직접매칭해주세요</p>
                                                        <div class="custom-control custom-switch custom-control-inline">
                                                            <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="nosku" @change="noskuid(match)">
                                                            <label class="custom-control-label" for="customSwitch1">SKU ID 없음</label>
                                                        </div>
                                                        <div class="row" v-if="!nosku">
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" placeholder="SKU ID 검색" v-model="sku검색">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" placeholder="상품명검색" v-model="상품명검색">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" placeholder="옵션명 (중국어) 검색" v-model="옵션명검색">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-2">
                                                                <button @click="searchbutton" type="button" class="btn btn-primary h-75">전체검색</button>
                                                            </div>
                                                        </div>
                                                    </b-dropdown-form>
                                                    <b-dropdown-divider v-if="showmatch"></b-dropdown-divider>
                                                    <div class="col-md-12" v-if="showmatchspinner">
                                                        <div class="text-center">
                                                            <img style="width:5%;" :src="require('../../../assets/images/small/loading.gif')">
                                                        </div>
                                                    </div>
                                                    <div v-if="showmatch">
                                                        <b-dropdown-item href="#" v-for="a,i in matches" :key="i" @click="productmatching(a,match)">
                                                            <div class="d-flex align-items-center">
                                                                <img :src="a.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                <div>
                                                                    {{a.상품명}}
                                                                    <p class="mb-0"><small>{{a["SKU ID"]}}&nbsp;&nbsp;{{a.옵션1_중국어}}&nbsp;&nbsp;{{a.옵션2_중국어}}</small></p>
                                                                </div>
                                                            </div>
                                                        </b-dropdown-item>
                                                    </div>
                                                </b-dropdown>
                                                <span v-if="match.상품매칭[0] != '직접선택'">{{match.상품매칭.length}}건</span>
                                                <b-dropdown class="my-class" size="xl" dropright variant="link" toggle-class="text-decoration-none" no-caret v-if="match.상품매칭[0] != '직접선택'" >
                                                    <template #button-content>
                                                    &#x1f50d;<span class="sr-only">Search</span>
                                                    </template>
                                                    <b-dropdown-form href="#">
                                                        <p>상품을 매칭해주세요</p>
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" placeholder="SKU ID 검색" v-model="sku검색" @change="searchbuttonin(match,sku검색)">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" placeholder="상품명검색" v-model="상품명검색" @change="searchbuttonin(match,상품명검색)">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" placeholder="옵션명 (중국어) 검색" v-model="옵션명검색" @change="searchbuttonin(match,옵션명검색)">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-2">
                                                                <button @click="searchbutton" type="button" class="btn btn-primary h-75">전체검색</button>
                                                            </div>
                                                        </div>
                                                    </b-dropdown-form>
                                                    <b-dropdown-divider v-if="showmatch"></b-dropdown-divider>
                                                    <div v-if="showmatch">
                                                        <b-dropdown-item href="#" v-for="a,i in matches" :key="i" @click="productmatching(a,match)">
                                                            <div class="d-flex align-items-center">
                                                                <img :src="a.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                <div>
                                                                    {{a.상품명}}
                                                                    <p class="mb-0"><small>{{a["SKU ID"]}}&nbsp;&nbsp;{{a.옵션1_중국어}}&nbsp;&nbsp;{{a.옵션2_중국어}}</small></p>
                                                                </div>
                                                            </div>
                                                        </b-dropdown-item>
                                                    </div>
                                                    <div v-if="!showmatch">
                                                        <b-dropdown-item href="#" v-for="a,i in match.상품매칭" :key="i" @click="productmatching(a,match)">
                                                            <div class="d-flex align-items-center">
                                                                <img :src="a.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                <div>
                                                                    {{a.상품명}}
                                                                    <p class="mb-0"><small>{{a["SKU ID"]}}&nbsp;&nbsp;{{a.옵션1_중국어}}&nbsp;&nbsp;{{a.옵션2_중국어}}</small></p>
                                                                </div>
                                                            </div>
                                                        </b-dropdown-item>
                                                    </div>
                                                </b-dropdown>
                                            </td>
                                            <td><div :class="matchclass(match.요청매칭상태)">{{match.요청매칭상태}}</div></td>
                                            <td>
                                                <div class="cursor-pointer" @click="openmodalreq(match.요청매칭,index)">
                                                    <template>
                                                        &#x1f50d;<span class="sr-only">Search</span>
                                                    </template>
                                                </div>
                                                <b-modal :id="'modalreq'+index" size="xl" title="구매요청내역 매칭" ok-title="적용" cancel-title="취소" @ok="reqmatching(match)">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" placeholder="바코드/상품명/옵션명(중국어) 검색" v-model="searchkeyword" @change="searchreq(match)">
                                                    </div>
                                                    <div class="table-responsive" v-if="showaddmatchlist">
                                                        <table id="datatablereq" class="table data-table table-striped dataTable" style="font-size:80%">
                                                            <thead>
                                                                <tr class="text-center ligth ligth-data">
                                                                    <th>
                                                                        <div class="checkbox d-inline-block">
                                                                            <input type="checkbox" class="checkbox-input" id="checkbox1" v-model="allcheckreqlists" @change="allcheck">
                                                                            <label for="checkbox1" class="mb-0"></label>
                                                                        </div>
                                                                    </th>
                                                                    <th style="font-size:100%">업체명</th>
                                                                    <th style="font-size:100%">요청일</th>
                                                                    <th style="font-size:100%">요청타입</th>
                                                                    <th style="font-size:100%">묶음배송</th>
                                                                    <th style="font-size:100%">진행현황</th>
                                                                    <th style="font-size:100%;min-width:300px;overflow:hidden;text-overflow: ellipsis;">제품정보</th>
                                                                    <th style="font-size:100%">구매요청수량</th>
                                                                    <th style="font-size:100%">판매구성수량</th>
                                                                    <th style="font-size:100%">구매링크</th>
                                                                    <th style="font-size:100%">옵션1_중국어</th>
                                                                    <th style="font-size:100%">옵션1_중국어</th>
                                                                    <th style="font-size:100%">구매재고</th>
                                                                    <th style="font-size:100%">발주번호</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(list,index) in addmatchlist" :key="index">
                                                                    <td>
                                                                        <div class="checkbox d-inline-block">
                                                                            <input type="checkbox" class="checkbox-input" v-model="list.checked">
                                                                            <label class="mb-0"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="text-center">{{list.companyid}}</td>
                                                                    <td class="text-center">{{getdate(list.요청일)}}</td>
                                                                    <td class="text-center">{{list.reqtype}}</td>
                                                                    <td class="text-center">{{list.묶음구성여부 ? list.묶음구성수량 + '개' : ""}}<br><small>{{list.묶음구성여부 ? '(' + list.바코드 + ")" : ""}}</small></td>
                                                                    <td class="text-center">{{list.진행현황}}</td>
                                                                    <td>
                                                                        <div class="d-flex align-items-center">
                                                                            <img :src="list.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                            <div>
                                                                                {{list.상품명}}
                                                                                <p class="mb-0"><small>{{list.SKUID}}</small></p>
                                                                                <p class="mb-0"><small>{{list.바코드}}</small></p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="text-center">{{list.구매요청수량}}</td>
                                                                    <td class="text-center">{{list.판매구성수량}}</td>
                                                                    <td class="text-center"><a :href="list.구매링크" target="_blank"><b-icon icon="link" scale="2" variant="secondary"></b-icon></a></td>
                                                                    <td class="text-center">{{list.옵션1_중국어}}</td>
                                                                    <td class="text-center">{{list.옵션2_중국어}}</td>
                                                                    <td class="text-center">{{list.구매재고}}</td>
                                                                    <td class="text-center">{{list.selectpo}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </b-modal>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center" v-if="match.등록이미지">
                                                    <div>
                                                        <img :src="match.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                        <p class="mb-0 text-center"><small>{{match.SKUID}}</small></p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{match.주문수량}}</td>
                                            <td>{{match.판매처}}</td>
                                            <td class="text-center"><a :href="match.상품링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a></td>
                                            <td>{{match.중국어옵션1}}</td>
                                            <td>{{match.중국어옵션2}}</td>
                                            <td>{{match.주문번호}}</td>
                                            <td>
                                                <div class="d-flex align-items-center list-action">
                                                    <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete"
                                                        href="javascript:void(0)"><i class="ri-delete-bin-line mr-0"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tableList>
                            </div>
                        </div>
                    </b-modal>

                </div>
            </div>

            <div class="col-lg-12">
            <card>
                <div class="card-header d-flex justify-content-between">
                    <div class="header-title">
                        <h4 class="card-title">
                            <div class="row align-items-center">
                                <div class="col-6 ml-3">
                                    <b-form-input type="text" class="form-control" list="my-list-id" @keyup.enter="importtextchange($event)" placeholder="업체ID" v-model="업체명"></b-form-input>
                                    <datalist id="my-list-id">
                                        <option v-for="companyid in companyids" :key="companyid">{{ companyid }}</option>
                                    </datalist>
                                </div>
                                <div class="col-2"><button type="button" :class="importtext=='Connect' ? 'btn btn-primary mr-2' : 'btn btn-success mr-2'" @click="importcompanyinfo">{{importtext}}</button></div>
                            </div>
                        </h4>
                        <div class="col-md-12" v-if="showimportspinner">
                            <div class="text-center">
                                <img style="width:20%;" :src="require('../../../assets/images/small/loading.gif')">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="p-3">
                        <form  @submit.prevent="submit" >
                            <div class="row">

                                <div class="col-md-12">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio7" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="today" @change="changedate()">
                                        <label class="custom-control-label" for="customRadio7">오늘</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio8" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="week" @change="changedate()">
                                        <label class="custom-control-label" for="customRadio8">최근7일</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio9" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="month" @change="changedate()">
                                        <label class="custom-control-label" for="customRadio9">최근1개월</label>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-2">
                                    <b-input-group>
                                        <select class="custom-select col-md-2" style="height:40px" v-model="selectoption">
                                            <option>구매일자</option>
                                            <!-- <option>입고예정일</option> -->
                                        </select>
                                        <b-form-datepicker id="example-datepicker1" size="sm" v-model="startdate" class="mb-2"></b-form-datepicker>
                                        <p>~</p>
                                        <b-form-datepicker id="example-datepicker2" size="sm" v-model="enddate" class="mb-2"></b-form-datepicker>

                                    </b-input-group>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="구매주문번호" v-model="주문번호">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="구매송장번호" v-model="운송장번호">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="구매처" v-model="판매처">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="SKU ID" v-model="SKUID">
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-primary mr-2 mt-1" @click="search">검색</button>
                            <div class="col-md-12" v-if="showsearchspinner">
                                <div class="text-center">
                                    <img style="width:5%;" :src="require('../../../assets/images/small/loading.gif')">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </card>
            </div>

            <div class="col-lg-12" v-if="showlist">
                <div class="p-3">
                    <div class="row">
                        <b-button variant="outline-primary" class="mb-1 mr-2" @click="showtotal">
                        전체 <b-badge variant="primary" class="ml-2">{{total}}</b-badge>
                        </b-button>
                        <b-button variant="outline-light" class="mb-1 mr-2" @click="showa">
                        발송전<b-badge variant="light" class="ml-2">{{발송전}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showb">
                        발송지연<b-badge variant="warning" class="ml-2">{{발송지연}}</b-badge>
                        </b-button>
                        <b-button variant="outline-primary" class="mb-1 mr-2" @click="showc">
                        발송완료<b-badge variant="primary" class="ml-2">{{발송완료}}</b-badge>
                        </b-button>
                        <b-button variant="outline-danger" class="mb-1 mr-2" @click="showd">
                        배송지연예정<b-badge variant="danger" class="ml-2">{{배송지연예정}}</b-badge>
                        </b-button>
                        <b-button variant="outline-secondary" class="mb-1 mr-2" @click="showe">
                        배송지연<b-badge variant="secondary" class="ml-2">{{배송지연}}</b-badge>
                        </b-button>
                        <b-button variant="outline-success" class="mb-1 mr-2" @click="showf">
                        구매확정<b-badge variant="success" class="ml-2">{{구매확정}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showg">
                        입고지연<b-badge variant="warning" class="ml-2">{{입고지연}}</b-badge>
                        </b-button>
                    </div>
                    <div class="row">
                        <b-button variant="outline-primary" class="mb-1 mr-2" @click="showk">
                        배송완료<b-badge variant="primary" class="ml-2">{{배송완료}}</b-badge>
                        </b-button>
                        <b-button variant="outline-success" class="mb-1 mr-2" @click="showh">
                        입고완료<b-badge variant="success" class="ml-2">{{입고완료}}</b-badge>
                        </b-button>
                        <b-button variant="outline-secondary" class="mb-1 mr-2" @click="showi">
                        입고수량오류<b-badge variant="secondary" class="ml-2">{{입고수량오류}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showj">
                        입고상품오류<b-badge variant="warning" class="ml-2">{{입고상품오류}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showl">
                        오류입고처리<b-badge variant="warning" class="ml-2">{{오류입고처리}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showm">
                        오류반품처리<b-badge variant="warning" class="ml-2">{{오류반품처리}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="shown">
                        오류폐기처리<b-badge variant="warning" class="ml-2">{{오류폐기처리}}</b-badge>
                        </b-button>
                        <b-button variant="outline-warning" class="mb-1 mr-2" @click="showo">
                        오류입고대기<b-badge variant="warning" class="ml-2">{{오류입고대기}}</b-badge>
                        </b-button>
                        <b-button variant="outline-success" class="mb-1 mr-2" @click="showp">
                        반품폐기완료<b-badge variant="success" class="ml-2">{{반품폐기완료}}</b-badge>
                        </b-button>
                    </div>
                </div>
            </div>

            <div class="col-lg-12" v-if="showlist">
                <CDataTable :headers="headers" :items="purchaselists" v-model="tableSelectedItem" :show-select="true" itemKey="_id">
                    <template v-slot:배송상태="{item}">
                        <a :href="logislink(item)" target="_blank"><div :class="logisclass(item)" style="padding: 8px 10px">{{item.배송상태}}</div></a>
                    </template>
                    <template v-slot:주문날짜="{item}">
                        {{getdate(item.주문날짜)}}
                    </template>
                    <template v-slot:입고날짜="{item}">
                        {{item.입고날짜 === undefined ? '' : getdate(item.입고날짜)}}
                    </template>
                    <template v-slot:상품명="{item}">
                        <div v-b-modal="modalID(item._id)" @click="modaldata(item)" style="padding: 15px 20px">
                            <div class="d-flex align-items-center">
                                <img :src="item.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                <div style="width: 300px">
                                    {{item.상품명}}
                                    <p class="mb-0" style="color: #676E8A"><small>{{item.SKUID}}</small></p>
                                    <p class="mb-0" style="color: #676E8A"><small>{{item.바코드}}</small></p>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:주문수량="{item}">
                        <div v-if="!showedittrackingno">
                            {{item.주문수량}}
                        </div>
                        <div v-if="showedittrackingno">
                            <input type="text" class="form-control" placeholder="상품명검색" v-model="item.주문수량" @change="editpurchase(item)">
                        </div>
                    </template>
                    <template v-slot:상품링크="{item}">
                        <a :href="item.상품링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a>
                    </template>
                    <template v-slot:운송장번호="{item}">
                        <div v-if="!showedittrackingno">
                            {{item.운송장번호}}
                        </div>
                        <div v-if="showedittrackingno">
                            <input type="text" class="form-control" placeholder="상품명검색" v-model="item.운송장번호" @change="editpurchase(item)">
                        </div>
                    </template>
                    <template v-slot:구매단가="{item}">
                        {{parseInt(item.총구매단가)}}
                    </template>
                    <template v-slot:action="{item}">
                        <div class="d-flex align-items-center list-action">
                            <a v-b-modal="modalID(item._id)" @click="modaldata(item)" class="badge badge-info mr-2" data-toggle="tooltip" v-b-tooltip.top title="View"
                               href="javascript:void(0)" style="padding: 8px 10px"><i class="ri-eye-line mr-0"></i></a>
                            <b-modal :id="'modal'+item._id" size="xl" scrollable title="제품상세" ok-title="OK" cancel-title="Close" class="detail-modal" @ok="refreshpage(item)">
                                <div class="row">
                                    <div class="col-md-6">
                                        <img class="w-100 d-block" :src="item.등록이미지" style="border:1px solid silver; border-radius: 30px">
                                    </div>
                                    <div class="col-md-6">
                                        <p style="font-size:100%">주문수량 : {{item.주문수량}}</p>
                                        <p style="font-size:100%">구매요청수량 : {{item.요청매칭 ? parseInt(item.요청매칭[0].구매요청수량) : ""}}</p>
                                        <p style="font-size:100%">판매구성 : {{item.판매구성수량}}</p>
                                        <!-- <div class="mb-4">
                                            <div class="row text-center ml-1" id="printMe" style="border:1px solid silver;width:300px">
                                                <div class="col-12 text-center mb-0">
                                                    <p class="mb-0" style="font-size:90%;">Made in China&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>SKU : {{purchase.SKUID}}</span></p>
                                                </div>
                                                <div class="col-12 text-center mb-0">
                                                    <barcode :value="purchase.바코드" :tag="bctag" :options="{ lineColor: '#0275d8', text: 'Scan'}"></barcode>
                                                </div>
                                            </div>
                                            <div class="mt-2">
                                                <button class="btn btn-primary" type="button" @click="print">바코드출력</button>
                                            </div>
                                        </div> -->
                                        <p>상품명 : {{item.상품명}}</p>
                                        <p>SKU ID : {{item.SKUID}}</p>
                                        <p>바코드 : {{item.바코드}}</p>
                                        <p>구매링크 : <a :href="item.상품링크">{{item.상품링크}}</a></p>
                                        <p>옵션1_중국어 : {{item.중국어옵션1}}</p>
                                        <p>옵션2_중국어 : {{item.중국어옵션2}}</p>
                                        <p>[배송 요청사항]</p>
                                        <card style="color:blue;" v-if="item.요청매칭 && item.요청매칭.length > 0">
                                            <div class="ml-1 mt-1 mb-1 mr-1 row">
                                                <div class="col-5">
                                                    <span><b>[출고작업]</b></span><br>
                                                    <span>배치 : {{item.요청매칭[0].selectpo ? '발주배치' : '해당없음'}}</span><br>
                                                    <span>배치발주번호 : {{item.요청매칭[0].selectpo}}</span><br>
                                                    <span>출고유형 : {{item.요청매칭[0].요청내역.exportType.toUpperCase()}}</span><br>
                                                    <br>
                                                    <span><b>[검수 및 포장 작업]</b></span><br>
                                                    <span>기본검수 : {{item.요청매칭[0].요청내역.default ? 'Y' : 'N'}}</span><br>
                                                    <span>OPP봉투 : {{item.요청매칭[0].요청내역.opp ? 'Y' : 'N'}}</span><br>
                                                    <span>택배봉투(LDPE) : {{item.요청매칭[0].요청내역.LDPE ? 'Y' : 'N'}}</span><br>
                                                    <span>에어캡 : {{item.요청매칭[0].요청내역.airpacking ? 'Y' : 'N'}}</span><br>
                                                    <br>
                                                </div>
                                                <div class="col-7">
                                                    <span><b>[라벨 및 원산지 작업]</b></span><br>
                                                    <span>바코드 : {{item.요청매칭[0].요청내역.barcode ? 'Y' : 'N'}}</span><br>
                                                    <span>한글표시사항 : {{item.요청매칭[0].요청내역.한글표시사항 ? 'Y' : 'N'}}</span><br>
                                                    <span>원산지스티커 : {{item.요청매칭[0].요청내역.sticker ? 'Y' : 'N'}}</span><br>
                                                    <span>도장 : {{item.요청매칭[0].요청내역.stamp ? 'Y' : 'N'}}</span><br>
                                                    <span>택총 : {{item.요청매칭[0].요청내역.tag ? 'Y' : 'N'}}</span><br>
                                                    <br>
                                                    <span><b>[통관 정보]</b></span><br>
                                                    <span>신고영문명 : {{item.요청매칭[0].신고영문명 ? item.요청매칭[0].신고영문명 : ''}}</span><br>
                                                    <span>신고단가(USD) : {{item.요청매칭[0].신고가격 ? item.요청매칭[0].신고가격 : ''}}</span><br>
                                                    <span>HS-CODE : {{item.요청매칭[0].HSCODE ? item.요청매칭[0].HSCODE : ''}}</span><br>
                                                    <br>
                                                    <span><b>[기타 구매대행 요청사항]</b></span><br>
                                                    <span>{{item.요청매칭[0].요청내역.reqcontent}}</span>
                                                </div>
                                            </div>
                                        </card>
                                        <p :style="item.입고오류유형 == '' ? 'font-size:120%;font-weight:bold;color:black' : 'font-size:120%;font-weight:bold;color:red'">입고오류유형 : {{item.입고오류유형 ? item.입고오류유형 : ''}}</p>
                                        <p style="font-size:120%;font-weight:bold;color:red" v-if="item.입고오류유형 == '수량불일치'">입고수량 : {{item.입고수량}}</p>
                                        <p style="font-size:120%;font-weight:bold;color:red" v-if="item.입고오류유형 != '' && item.입고오류유형 != '수량불일치'">입고오류내용 : {{item.입고오류내용}}</p>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <button :class="importerrorbutton == '입고처리' ? 'btn btn-secondary' : 'btn btn-danger'" type="button" @click="importerror(item)">{{importerrorbutton}}</button>
                                            </div>
                                            <div class="col-md-8" v-if="showimporterror">
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadio15" name="customRadio-4" class="custom-control-input" @change="changeimporterror(item)" v-model="item.처리방법" value="입고완료처리">
                                                    <label class="custom-control-label" for="customRadio15">입고완료처리</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadio16" name="customRadio-4" class="custom-control-input" @change="changeimporterror(item)" v-model="item.처리방법" value="반품">
                                                    <label class="custom-control-label" for="customRadio16">반품</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadio17" name="customRadio-4" class="custom-control-input" @change="changeimporterror(item)" v-model="item.처리방법" value="폐기">
                                                    <label class="custom-control-label" for="customRadio17">폐기</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadio18" name="customRadio-4" class="custom-control-input" @change="changeimporterror(item)" v-model="item.처리방법" value="입고대기">
                                                    <label class="custom-control-label" for="customRadio18">입고대기</label>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2" v-if="showimporterror && showimporterror1">
                                                <div class="form-group">
                                                    <label for="exampleFormControlTextarea1">반품정보</label>
                                                    <input type="text" class="form-control" placeholder="반품수취인" v-model="item.반품수취인">
                                                    <input type="text" class="form-control" placeholder="반품연락처" v-model="item.반품연락처">
                                                    <textarea class="form-control" placeholder="반품주소" id="exampleFormControlTextarea1" rows="3" v-model="item.반품주소"></textarea>
                                                    <input type="text" class="form-control" placeholder="반품운송장번호" v-model="item.반품운송장번호">
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2" v-if="showimporterror && showimporterror2">
                                                <div class="form-group">
                                                    <label for="exampleFormControlTextarea1">입고대기 사유</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="item.입고대기사유"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-modal>
                            <a class="badge bg-success mr-2" style="padding: 8px 10px" data-toggle="tooltip" v-b-tooltip.top title="Edit"
                               href="javascript:void(0)" @click="edittrackingno"><i class="ri-pencil-line mr-0"></i></a>
                            <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete"
                               href="javascript:void(0)" @click="deleteitem(item)" style="padding: 8px 10px"><i class="ri-delete-bin-line mr-0"></i></a>
                        </div>
                    </template>
                </CDataTable>
            </div>


            <div class="col-lg-12" v-if="showlist">
                <button type="button" class="btn btn-primary mr-2 mt-1" @click="download">다운로드</button>
                <button type="button" class="btn btn-success mr-2 mt-1" v-b-toggle.my-collapse>일괄변경</button>
                <!-- <button type="button" class="btn btn-secondary mr-2 mt-1" @click="deletelists">일괄삭제</button> -->
                <button type="button" class="btn btn-secondary mr-2 mt-1" @click="deleteall">전체삭제</button>
            </div>
            <div class="col-lg-3 mt-2 mb-4" v-if="showlist">
                <b-collapse id="my-collapse">
                    <b-card title="">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="주문번호" v-model="일괄주문번호">
                        </div>
                        <div class="form-group">
                            <input type="number" class="form-control" placeholder="주문수량" v-model="일괄주문수량">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="운송장번호" v-model="일괄운송장번호">
                        </div>
                        <button type="button" class="btn btn-success mr-2 mt-1" @click="editlists">적용</button>
                    </b-card>
                </b-collapse>
            </div>
        </div>
        <!-- Page end  -->
    </div>
</template>
<script>
import * as XLSX from 'xlsx';

import axios from 'axios';
import CDataTable from "@/components/common/CDataTable.vue";
export default {
    name:'ListPurchases',
    components: {CDataTable},
    data(){
        return{
            headers: [
                {text: '배송상태', value: '배송상태', align: 'left', width: 100, isSlot: true},
                {text: '배송기간', value: '배송기간', align: 'left', width: 100, isSlot: false},
                {text: '주문날짜', value: '주문날짜', align: 'left', width: 100, isSlot: true},
                {text: '입고날짜', value: '입고날짜', align: 'left', width: 100, isSlot: true},
                {text: '상품정보', value: '상품명', align: 'left', width: 450, isSlot: true},
                {text: '주문수량', value: '주문수량', align: 'left', width: 100, isSlot: true},
                {text: '판매처', value: '판매처', align: 'left', width: 100, isSlot: false},
                {text: '주문번호', value: '주문번호', align: 'left', width: 100, isSlot: false},
                {text: '상품링크', value: '상품링크', align: 'left', width: 100, isSlot: true},
                {text: '운송장번호', value: '운송장번호', align: 'left', width: 100, isSlot: true},
                {text: '총구매단가', value: '구매단가', align: 'left', width: 120, isSlot: true},
                {text: 'Action', value: 'action', align: 'left', width: 120, isSlot: true},
            ],
            tableSelectedItem: [],
            showupload:true,
            uploadtext:'배송현황 일괄 업데이트',
            addmatchlist: [],

            allcheckreqlists: false,
            searchkeyword:'',
            companyids: [],
            reqlists:[],
            reqdates:[],
            nosku:false,

            title:"구매내역",
            desc1:"1688구매내역을 업로드 해주세요. 구매내역 업로드 시 '구매재고'에 자동으로 추가됩니다.",
            desc2:"'구매재고'가 입고될 경우 재고등록 메뉴에서 입고로 전환해주세요.",
            link:{  name: 'purchase.addpurchase'},
            linktext:"1688구매내역 업로드",

            업체명:'',
            importtext:'Connect',
            showimportspinner: false,

            showspinner: false,
            showsearchspinner:false,
            showlist: false,
            showmatchlist:false,
            matchlists: [],
            purchaselists: [],
            purchaselistsinit: [],

            uploadstatus: 'excel file upload',
            환율:0,

            checkbox1:false,
            sku검색:'',
            상품명검색:'',
            옵션명검색:'',

            showmatch:false,
            showmatchspinner:false,
            matches:[],

            selectdate: 'today',
            selectdate1: 'today',
            selectoption:'구매일자',
            selectoption1:'구매요청일',
            startdate: this.getTodaywith(),
            enddate: this.getTodaywith(),
            startdate1: this.getTodaywith(),
            enddate1: this.getTodaywith(),

            주문번호:'',
            운송장번호:'',
            판매처:'',
            발주번호:'',
            SKUID:'',
            바코드:'',

            showedittrackingno:false,

            total: 0,
            발송전 : 0,
            발송지연 : 0,
            발송완료 : 0,
            배송지연예정 : 0,
            배송지연 : 0,
            구매확정 : 0,
            입고지연 : 0,
            입고완료 : 0,
            입고수량오류 : 0,
            입고상품오류 : 0,
            배송완료 : 0,
            오류입고처리 : 0,
            오류반품처리 : 0,
            오류폐기처리 : 0,
            오류입고대기 : 0,
            반품폐기완료 : 0,
            showgetreqdate : true,

            importerrorbutton: '입고처리',
            showimporterror: false,
            showimporterror1 : false,
            showimporterror2 : false,

            currentpurchase : {},
            currentindex: 0,

            일괄주문번호: '',
            일괄주문수량: '',
            일괄운송장번호: '',
            showaddmatchlist: false,
            // temparr : [],
        }
    },
    mounted(){
		if(this.$store.state.purchase.length > 0){
			this.업체명 = this.$store.state.purchase[0].companyid;
			this.importtext = 'Connected';
		}
        this.importcompanyids()
        this.importreqlists();

	},
    methods: {
        openfileinput(){
            this.$refs.pdballupload.click();
        },
        openmodalreq(arr,index){
            this.showaddmatchlist = false;
            arr.forEach(e => {
                e.checked = false;
            })
            this.addmatchlist = arr;
            setTimeout(()=>{this.showaddmatchlist = true;},10)
            this.$bvModal.show('modalreq'+index);
        },
        searchreq(match){
            if(this.searchkeyword.length == 0){
                return
            }
            this.showaddmatchlist = false;
            if(match.요청매칭.length == 0){
                if(confirm('검색할 내역이 없습니다. 전체 요청내역에서 검색하시겠습니까?')){
                    var result = this.reqlists.filter(e => e.진행현황 == '구매확인' && (e.상품명.includes(this.searchkeyword) || e.옵션1_중국어.includes(this.searchkeyword) || e.옵션2_중국어.includes(this.searchkeyword) || e.바코드.includes(this.searchkeyword)));
                    if(result.length == 0){
                        alert('구매확인된 요청내역에서는 검색결과가 없습니다.')
                    } else {
                        this.addmatchlist = this.reqlists.filter(e => e.진행현황 == '구매확인' && (e.상품명.includes(this.searchkeyword) || e.옵션1_중국어.includes(this.searchkeyword) || e.옵션2_중국어.includes(this.searchkeyword) || e.바코드.includes(this.searchkeyword)));
                    }
                }
            } else {
                this.addmatchlist = match.요청매칭.filter(e => e.진행현황 == '구매확인' && (e.상품명.includes(this.searchkeyword) || e.옵션1_중국어.includes(this.searchkeyword) || e.옵션2_중국어.includes(this.searchkeyword) || e.바코드.includes(this.searchkeyword)));
            }
            setTimeout(()=>{this.showaddmatchlist = true;},10)
        },
        getreqdates(){
            this.reqdates = [];
            var req = this.reqlists.filter(f => f.진행현황 === "구매확인");
            req.forEach(e => {
                if(!this.reqdates.find( ele => ele.date == this.getdate(e.요청일))){
                    this.reqdates.push({
                        date : this.getdate(e.요청일),
                        checked : true,
                    })
                }
            })
        },
        noskuid(match){
            if(this.nosku){
                match.매칭상태 = '매칭완료'
            } else {
                match.매칭상태 = '매칭필요'
            }
        },
        allcheck(){
            if(this.allcheckreqlists){
                this.addmatchlist.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.addmatchlist.forEach(e => {
                    e.checked = false;
                })
            }
        },
        async importreqlists(){
            if(this.업체명 == 'nomadclub'){
                axios.post('/api/reqpurchase/getnomadclub',{companyid:this.업체명})
                .then( result => {
                    if (result.data.요청결과 == 'ok'){
                        this.reqlists = result.data.reqpurchase;
                        this.getreqdates();
                        this.showgetreqdate = false
                        console.log(this.reqlists)
                    }
                }).catch((e)=>{
                    alert('reqpurchase 가져오기 실패 (639)')
                    console.log(e);
                    return true
                })
            } else {
                axios.post('/api/reqpurchase/getlist',{companyid:this.업체명})
                .then( result => {
                    if (result.data.요청결과 == 'ok'){
                        this.reqlists = result.data.reqpurchase;
                        this.getreqdates();
                        this.showgetreqdate = false
                        console.log(this.reqlists)
                    }
                }).catch((e)=>{
                    alert('reqpurchase 가져오기 실패 (639)')
                    console.log(e);
                    return true
                })
            }
        },
        importcompanyids(){
			axios.post('/api/getcompanyids',{})
            .then( result => {
				if (result.data.요청결과 == 'success'){
					result.data.result.forEach(e => {
                        if(e.auth.showimport || e.auth.showgrowth){
                            if(!this.companyids.includes(e.companyid)){
                                this.companyids.push(e.companyid);
                            }
                        }
					})
				} else {
					alert(result.data.사유)
				}
            }).catch((e)=>{
                alert('companyid 가져오기 실패 (1413)')
                console.log(e);
                return true
            })
		},
        editlists(){
            if(!this.일괄주문번호){
                alert('변경할 주문번호를 입력해 주세요')
                return
            }
            if(confirm('총 ' + this.purchaselists.filter(e => e.주문번호.toString() == this.일괄주문번호.toString()).length + '건의 구매내역을 변경하시겠습니까?')){
                this.purchaselists.filter(e => e.주문번호.toString() == this.일괄주문번호.toString()).forEach(e => {
                    if(this.일괄주문수량){
                        e.주문수량 = parseInt(this.일괄주문수량);
                    }
                    if(this.일괄운송장번호){
                        e.운송장번호 = this.일괄운송장번호;
                    }
                })
                this.editpurchases(this.purchaselists.filter(e => e.주문번호.toString() == this.일괄주문번호.toString()))
            }
        },
        download(){
            var arr = [];

            if (this.tableSelectedItem) {
                this.tableSelectedItem.forEach(e => {
                    var f = e.요청매칭[0];
                    if(f.selectpo){
                        var 발주센터 = f.polists.find(ele => ele.발주번호.toString() == f.selectpo.toString())?.발주센터;
                        var 입고예정일 = f.polists.find(ele => ele.발주번호.toString() == f.selectpo.toString())?.입고예정일;
                        var sku발주수량 = parseInt(f.polists.find(ele => ele.발주번호.toString() == f.selectpo.toString())?.발주세부내역.find(ele => parseInt(ele.SKUID) == f.SKUID)?.발주수량.toString().replaceAll(',',''));
                    } else {
                        발주센터 = '';
                        입고예정일 = '';
                        sku발주수량 = 0;
                    }
                    console.log(발주센터)
                    arr.push({
                        companyid : e.companyid,
                        SKUID : e.SKUID,
                        바코드 : e.바코드,
                        site : e.site,
                        상품링크 : e.상품링크,
                        상품번호 : e.상품번호,
                        구매sku : e.구매sku,
                        구매단가 : e.구매단가,
                        적용환율 : e.적용환율,
                        등록이미지 : e.등록이미지,
                        배송기간 : e.배송기간,
                        배송단가 : e.배송단가,
                        배송상태 : e.배송상태,
                        상품명 : e.상품명,
                        운송장번호 : e.운송장번호,
                        입고수량 : e.입고수량,
                        입고오류내용 : e.입고오류내용,
                        입고오류유형 : e.입고오류유형,
                        입고오류이미지 : e.입고오류이미지,
                        주문날짜 : new Date(e.주문날짜),
                        주문번호 : e.주문번호,
                        주문수량 : e.주문수량,
                        판매구성수량 : e.판매구성수량,
                        중국어옵션1 : e.중국어옵션1,
                        중국어옵션2 : e.중국어옵션2,
                        '총구매단가(CNY)' : e.구매단가 + e.배송단가 + e.할인단가,
                        '총구매단가(KRW)' : e.총구매단가,
                        판매처 : e.판매처,
                        할인단가 : e.할인단가,
                        HSCODE : f.HSCODE,
                        요청타입 : f.reqtype,
                        발주배치여부 : f.요청내역.poarrange,
                        발주번호 : f.selectpo,
                        발주센터 : 발주센터,
                        입고예정일 : 입고예정일,
                        SKU발주수량 : sku발주수량,
                        구매요청수량 : parseInt(f.구매요청수량),
                        나머지수량재고동봉여부 : f.재고동봉 ? 'Y' : 'N',
                        묶음구성수량 : f.묶음구성수량,
                        묶음구성여부 : f.묶음구성여부 ? 'Y' : 'N',
                        '신고가격(USD)' : f.신고가격,
                        신고영문명 : f.신고영문명,
                        구매요청일 : new Date(f.요청일),

                        출고방법 : f.요청내역.exportType,
                        목적지 : f.요청내역.destination ? f.요청내역.destination : "coupang",

                        "기본검수/분류/포장" : f.요청내역.default,
                        바코드라벨 : f.요청내역.barcode,

                        포장방법위임 : f.요청내역.auto ? "O" : "",
                        LDPE : f.요청내역.LDPE ? "O" : "",
                        OPP : f.요청내역.OPP ? "O" : "",
                        재포장생략 : (f.요청내역.auto == false && !f.요청내역.LDPE && !f.요청내역.OPP) ? "O" : "",

                        원산지스티커 : f.요청내역.sticker ? "O" : "",
                        라벨봉제 : f.요청내역.sewing ? "O" : "",
                        도장 : f.요청내역.stamp ? "O" : "",
                        택총 : f.요청내역.tag ? "O" : "",
                        에어캡포장 : f.요청내역.airpacking ? "O" : "",
                        중국어마스킹 : f.요청내역.masking ? "O" : "",


                        한글표시사항 : f.요청내역.한글표시사항,
                        기타요청사항 : f.요청내역.reqcontent
                    })
                })
            } else {
                this.purchaselists.forEach(e => {
                    var f = e.요청매칭[0];
                    if(f.selectpo){
                        var 발주센터 = f.polists.find(ele => ele.발주번호.toString() == f.selectpo.toString())?.발주센터;
                        var 입고예정일 = f.polists.find(ele => ele.발주번호.toString() == f.selectpo.toString())?.입고예정일;
                        var sku발주수량 = parseInt(f.polists.find(ele => ele.발주번호.toString() == f.selectpo.toString())?.발주세부내역.find(ele => parseInt(ele.SKUID) == f.SKUID)?.발주수량.toString().replaceAll(',',''));
                    } else {
                        발주센터 = '';
                        입고예정일 = '';
                        sku발주수량 = 0;
                    }
                    console.log(발주센터)
                    arr.push({
                        companyid : e.companyid,
                        SKUID : e.SKUID,
                        바코드 : e.바코드,
                        site : e.site,
                        상품링크 : e.상품링크,
                        상품번호 : e.상품번호,
                        구매sku : e.구매sku,
                        구매단가 : e.구매단가,
                        적용환율 : e.적용환율,
                        등록이미지 : e.등록이미지,
                        배송기간 : e.배송기간,
                        배송단가 : e.배송단가,
                        배송상태 : e.배송상태,
                        상품명 : e.상품명,
                        운송장번호 : e.운송장번호,
                        입고수량 : e.입고수량,
                        입고오류내용 : e.입고오류내용,
                        입고오류유형 : e.입고오류유형,
                        입고오류이미지 : e.입고오류이미지,
                        주문날짜 : new Date(e.주문날짜),
                        주문번호 : e.주문번호,
                        주문수량 : e.주문수량,
                        판매구성수량 : e.판매구성수량,
                        중국어옵션1 : e.중국어옵션1,
                        중국어옵션2 : e.중국어옵션2,
                        '총구매단가(CNY)' : e.구매단가 + e.배송단가 + e.할인단가,
                        '총구매단가(KRW)' : e.총구매단가,
                        판매처 : e.판매처,
                        할인단가 : e.할인단가,
                        HSCODE : f.HSCODE,
                        요청타입 : f.reqtype,
                        발주배치여부 : f.요청내역.poarrange,
                        발주번호 : f.selectpo,
                        발주센터 : 발주센터,
                        입고예정일 : 입고예정일,
                        SKU발주수량 : sku발주수량,
                        구매요청수량 : parseInt(f.구매요청수량),
                        나머지수량재고동봉여부 : f.재고동봉 ? 'Y' : 'N',
                        묶음구성수량 : f.묶음구성수량,
                        묶음구성여부 : f.묶음구성여부 ? 'Y' : 'N',
                        '신고가격(USD)' : f.신고가격,
                        신고영문명 : f.신고영문명,
                        구매요청일 : new Date(f.요청일),
                        바코드라벨링 : f.요청내역.barcode,
                        기본검수 : f.요청내역.default,
                        opp : f.요청내역.opp,
                        LDPE : f.요청내역.LDPE,
                        airpacking : f.요청내역.airpacking,
                        출고유형 : f.요청내역.exportType,
                        원산지스티커 : f.요청내역.sticker,
                        도장 : f.요청내역.stamp,
                        택총 : f.요청내역.tag,
                        한글표시사항 : f.요청내역.한글표시사항,
                        기타요청사항 : f.요청내역.reqcontent
                    })
                })
            }
            const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '구매및요청내역')
			XLSX.writeFile(workBook, '구매및요청내역.xlsx')
        },
        deleteindexfromarray(array,index){
            return array.slice(0,index).concat(array.slice(index+1))
        },
        async editpurchase(purchase){
            purchase.주문수량 = parseInt(purchase.주문수량);
            var arr = [];
            arr.push(purchase)
            await this.editpurchases(arr)
            this.edittrackingno()
        },
        editpurchases(arr){
            axios.post('/api/editpurchase',arr)
            .then( result => {
            if (result.data.요청결과 == '업데이트완료') {
                arr.forEach(e => {
                    this.$store.commit('updatepurchaseitem', e)
                    console.log(e.운송장번호)
                    console.log(this.$store.state.purchase.find(ele => ele._id == e._id).운송장번호)
                })
                alert("업데이트완료")
                return true
            }
            }).catch((e)=>{
                alert('업데이트 실패(580)')
                console.log(e);
                return true
            })
        },
        // deletelists(){
        //     if(this.purchaselists.filter(e => e.checked).length == 0){
        //         alert('선택된 구매내역이 없습니다.')
        //         return
        //     }
        //     this.deletearr(this.purchaselists.filter(e => e.checked));
        //     console.log(this.purchaselists.filter(e => e.checked))
        // },
        deleteall(){
            if(confirm('삭제된 내역은 복구가 불가능합니다. 그래도 전체내역을 삭제하시겠습니까?')){
                axios.post('/api/deletepurchase',this.purchaselists)
                .then( result => {
                if (result.data.요청결과 == '삭제완료') {
                    this.$store.commit('deletepurchase', this.purchaselists)
                    this.purchaselists = [];
                    alert(result.data.deletedCount + "개 구매내역 삭제완료")
                    this.search();
                } else {
                    alert('삭제 실패(578)')
                    // return true
                }
                }).catch((e)=>{
                    alert('삭제 실패(582)')
                    console.log(e);
                    // return true
                })
            }
        },
        deleteitem(purchase){
            var arr = []
            arr.push(purchase)
            this.deletearr(arr);
        },
        deletearr(delarr){
            if(delarr.length == 0 ){
                alert('삭제할 내역이 없습니다.')
                return
            }
            if(confirm('삭제된 내역은 복구가 불가능합니다. 총 '+ delarr.length +' 건을 삭제하시겠습니까?')){
                axios.post('/api/deletepurchase',delarr)
                .then( result => {
                if (result.data.요청결과 == '삭제완료') {
                    this.$store.commit('deletepurchase', delarr)
                    const selectedIndex = this.purchaselists.findIndex(i => i._id === delarr._id);
                    this.purchaselists.splice(selectedIndex, 1);
                    alert(result.data.deletedCount + "개 구매내역 삭제완료")
                    return true
                } else {
                    alert('삭제 실패(578)')
                    return true
                }
                }).catch((e)=>{
                    alert('삭제 실패(582)')
                    console.log(e);
                    return true
                })
            }
        },
        equal(k1,k2){
			JSON.stringify(k1) === JSON.stringify(k2)
		},
        modaldata(purchase){
            const selectedIndex = this.purchaselists.findIndex(i => i._id === purchase._id);
            this.currentpurchase = purchase;
            this.currentindex = selectedIndex;
            if(purchase.배송상태.includes('오류') || purchase.배송상태.includes('반품')){
                this.importerror(purchase)
                this.changeimporterror(purchase)
            }
        },
        async refreshpage(bvModalEvent){
             bvModalEvent.preventDefault()
            this.showlist = false;
            var purchase = this.purchaselists[this.currentindex]
            console.log(purchase)
            if(!this.equal(this.purchaselistsinit[this.currentindex],purchase)){
                console.log(purchase)
                if(this.purchaselistsinit[this.currentindex].처리방법){
                    console.log(this.purchaselistsinit[this.currentindex])
                    console.log(purchase)
                    if(purchase.처리방법 != this.purchaselistsinit[this.currentindex].처리방법){
                        console.log(purchase)
                        this.showlist = await this.updatepurchase(purchase)
                        this.$nextTick(() => {
                            this.$bvModal.hide('modal' + this.currentindex)
                        })
                    }
                } else {
                    console.log(purchase)
                    this.showlist = await this.updatepurchase(purchase)
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal' + this.currentindex)
                    })
                }
            } else {
                console.log(purchase)
                this.showlist = true
            }
        },
        async updatepurchase(purchase){
            var arr = [];
            arr.push(purchase);
            axios.post('/api/logisaddimport/updatepurchase',arr)
            .then( result => {
            if (result.data.요청결과 == '입고처리 완료') {
                this.$store.commit('purchaseupdate', result.data.purchase)
                return true
            } else {
                alert('업데이트 실패(579)')
                return true
            }
            }).catch((e)=>{
                alert('업데이트 실패(582)')
                console.log(e);
                return true

            })
		},
        changeimporterror(purchase){
            if(purchase.처리방법 == '입고완료처리'){
				this.showimporterror1 = false;
				this.showimporterror2 = false;
                purchase.배송상태 = '오류입고처리'
                return
            }
			if(purchase.처리방법 == '반품'){
				this.showimporterror1 = true;
				this.showimporterror2 = false;
                purchase.배송상태 = '오류반품처리'
                return
			}
            if(purchase.처리방법 == '폐기'){
				this.showimporterror1 = false;
				this.showimporterror2 = false;
                purchase.배송상태 = '오류폐기처리'
                return
            }
            if(purchase.처리방법 == '입고대기'){
				this.showimporterror1 = false;
				this.showimporterror2 = true;
				purchase.배송상태 = '오류입고대기'
                return
			}
		},
		importerror(purchase){
			if(this.importerrorbutton == '입고처리'){
                purchase.checked = true;
				this.showimporterror = true;
				this.importerrorbutton = '입고처리취소'
			} else {
                purchase.checked = false;
				this.showimporterror = false;
				this.importerrorbutton = '입고처리';
                if(purchase.입고오류유형){
                    purchase.입고오류유형 = this.purchaselistsinit.find(e => e._id == purchase._id).입고오류유형;
                    purchase.배송상태 = this.purchaselistsinit.find(e => e._id == purchase._id).배송상태;
                    purchase.입고수량 = this.purchaselistsinit.find(e => e._id == purchase._id).입고수량;
                    purchase.입고오류내용 = this.purchaselistsinit.find(e => e._id == purchase._id).입고오류내용;
                }
			}
		},
        importtextchange(){
			this.importtext = 'Connect';
		},
		importcompanyinfo(){
			if(this.importtext == 'Connect'){
				if(this.업체명){
					if(this.$store.state.purchase.length > 0 && this.업체명.toUpperCase() == this.$store.state.purchase[0].companyid.toUpperCase()){
						this.showimportspinner = false;
						this.importtext = 'Connected'
						return
					}
					this.showimportspinner = true;
					axios.post('/api/logisaddimport/importcompanyinfo',{
						companyid : this.업체명
					})
					.then( async (result) => {
					if (result.data.요청결과 == 'success') {
                        await this.importreqlists()
						this.$store.commit('dbupdate', result.data.dbs)
						this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
						this.showimportspinner = false;
						this.importtext = 'Connected'
					} else {
						alert('업체 Data Import 실패')
					}
					}).catch((e)=>{
						console.log(e);
					})
				} else {
					alert('업체ID를 기입해 주세요')
				}
			} else {
				var obj = {
					polist : [],
					productdb : [],
					purchase : [],
					returns : [],
					exportlist : [],
					stock : [],
				}
				this.$store.commit('dbupdate',obj);
				this.importtext = 'Connect';
				this.업체명 = '';
			}
		},
        modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
        edittrackingno(){
            this.showedittrackingno = !this.showedittrackingno;
        },
        showtotal(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit;
            this.showlist = true;
            }, 1);
        },
        showa(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '발송전')
            this.showlist = true;
            }, 1);
        },
        showb(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '발송지연')
            this.showlist = true;
            }, 1);
        },
        showc(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '발송완료')
            this.showlist = true;
            }, 1);
        },
        showd(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '배송지연예정')
            this.showlist = true;
            }, 1);
        },
        showe(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '배송지연')
            this.showlist = true;
            }, 1);
        },
        showf(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '구매확정')
            this.showlist = true;
            }, 1);
        },
        showg(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '입고지연')
            this.showlist = true;
            }, 1);
        },
        showh(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '입고완료')
            this.showlist = true;
            }, 1);
        },
        showi(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '입고수량오류')
            this.showlist = true;
            }, 1);
        },
        showj(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '입고상품오류')
            this.showlist = true;
            }, 1);
        },
        showk(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '배송완료')
            this.showlist = true;
            }, 1);
        },
        showl(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '오류입고처리')
            this.showlist = true;
            }, 1);
        },
        showm(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '오류반품처리')
            this.showlist = true;
            }, 1);
        },
        shown(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '오류폐기처리')
            this.showlist = true;
            }, 1);
        },
        showo(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '오류입고대기')
            this.showlist = true;
            }, 1);
        },
        showp(){
            this.showlist=false;
            setTimeout(() => {
            this.purchaselists = this.purchaselistsinit.filter(e => e.배송상태 === '반품폐기완료')
            this.showlist = true;
            }, 1);
        },
        logislink(purchase){
            var url = "https://trade.1688.com/order/new_step_order_detail.htm?orderId=" + purchase.주문번호 + "&tracelog=20120313bscentertologisticsbuyer&#logisticsTabTitle"
            return url
        },
        search(){
            if(this.importtext == 'Connect'){
                alert('업체ID를 입력 후 Connect해주세요')
                return
            }
            this.showlist = false;
            this.showsearchspinner = true;
            if(this.$store.state.purchase.length == 0){
                alert('구매리스트가 없습니다.')
                this.showsearchspinner = false;
            } else {
                this.searchpurchaselists();
                setTimeout(() => {
                    this.showsearchspinner = false;
                    this.showlist = true;
                },50)
            }
        },
        deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
        searchpurchaselists(){
            this.purchaselists = [];
            this.purchaselistsinit = [];
            var arr = this.$store.state.purchase;
            console.log(arr)
            if(this.주문번호){
                arr = arr.filter(e => e.주문번호 == this.주문번호)
            }
            if(this.운송장번호){
                arr = arr.filter(e => e.운송장번호 == this.운송장번호)
            }
            if(this.판매처){
                arr = arr.filter(e => e.판매처 == this.판매처)
            }
            if(this.SKUID){
                arr = arr.filter(e => e.SKUID == this.SKUID)
            }
            if(this.startdate && this.enddate){
                arr = arr.filter(e => this.getdate(e.주문날짜) >= this.startdate && this.getdate(e.주문날짜) <= this.enddate)
            }
            arr.forEach(e => {
                var temp = {}
                if(this.$store.state.productdb && this.$store.state.productdb.find(el => el.바코드 == e.바코드)){
                    temp = this.deepClone(this.$store.state.productdb.find(el => el.바코드 == e.바코드))
                    if(temp){
                        e.등록이미지 = temp.등록이미지 ? temp.등록이미지 : e.등록이미지 ? e.등록이미지 : '';
                        e.상품명 = temp.상품명 ? temp.상품명 : e.상품명 ? e.상품명 : '정보없음';
                        e.바코드 = temp.바코드 ? temp.바코드 : e.바코드 ? e.바코드 : '정보없음';
                    }
                } else {
                    e.등록이미지 = e.등록이미지 ? e.등록이미지 : '';
                    e.상품명 = e.상품명 ? e.상품명 : '정보없음';
                    e.바코드 = e.바코드 ? e.바코드 : '정보없음';
                }
                e.checked = false;
                this.purchaselists.push(this.deepClone(e))
                this.purchaselistsinit.push(this.deepClone(e))
            })
            console.log(this.purchaselists)
            this.badgeupdate(arr)
            this.주문번호 = '';
            this.운송장번호 = '';
            this.판매처 = '';
            this.SKUID = '';
        },
        badgeupdate(arr){
            this.total = arr.length;
            this.발송전 = arr.filter(e => e.배송상태 == '발송전').length;
            this.발송지연 = arr.filter(e => e.배송상태 == '발송지연').length;
            this.발송완료 = arr.filter(e => e.배송상태 == '발송완료').length;
            this.배송지연예정 = arr.filter(e => e.배송상태 == '배송지연예정').length;
            this.배송지연 = arr.filter(e => e.배송상태 == '배송지연').length;
            this.구매확정 = arr.filter(e => e.배송상태 == '구매확정').length;
            this.입고지연 = arr.filter(e => e.배송상태 == '입고지연').length;
            this.입고완료 = arr.filter(e => e.배송상태 == '입고완료').length;
            this.입고수량오류 = arr.filter(e => e.배송상태 == '입고수량오류').length;
            this.입고상품오류 = arr.filter(e => e.배송상태 == '입고상품오류').length;
            this.배송완료 = arr.filter(e => e.배송상태 == '배송완료').length;
            this.오류입고처리 = arr.filter(e => e.배송상태 == '오류입고처리').length;
            this.오류반품처리 = arr.filter(e => e.배송상태 == '오류반품처리').length;
            this.오류폐기처리 = arr.filter(e => e.배송상태 == '오류폐기처리').length;
            this.오류입고대기 = arr.filter(e => e.배송상태 == '오류입고대기').length;
            this.반품폐기완료 = arr.filter(e => e.배송상태 == '반품폐기완료').length;
        },
        changedate(){
            var now
            var target
            if(this.selectdate == 'today'){
                this.startdate = this.getTodaywith();
                this.enddate = this.getTodaywith();
            } else {
                if(this.selectdate == 'week'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*7

                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                } else {
                    if(this.selectdate == 'month'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*30
                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                    }
                }
            }
        },
        changedate1(){
            var now
            var target
            if(this.selectdate1 == 'today'){
                this.startdate1 = this.getTodaywith();
                this.enddate1 = this.getTodaywith();
            } else {
                if(this.selectdate1 == 'week'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*7

                    this.startdate1 = this.getdate(target);
                    this.enddate1 = this.getTodaywith();
                } else {
                    if(this.selectdate1 == 'month'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*30
                    this.startdate1 = this.getdate(target);
                    this.enddate1 = this.getTodaywith();
                    }
                }
            }
        },
        getTodaywith(){
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },
        getdate(timestamp){
            var date = new Date(timestamp);
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },
        handleOk(bvModalEvent) {
			bvModalEvent.preventDefault();
			this.handleSubmit();
		},
        cancel(){
            this.$nextTick(() => {
                // this.purchaselists = [];
                // this.matchlists = [];
				// this.stockdetail = [];
				// this.stockdetailinit = {};
				// this.stockdetailinitarr = [];
				// this.showchecklist = false;
				// this.modalcheck1 = false;
                this.$bvModal.hide('modal');
			})
        },
		handleSubmit() {
            if(this.purchaselists.filter(e => e.매칭상태 == '매칭필요' || e.요청매칭상태 == '매칭필요').length > 0){
                if(!confirm('매칭이 안된 상품이 있습니다. 매칭이 안된 제품은 제외하고 저장하시겠습니까?')){
                    return
                }
            }
            var arr = this.purchaselists.filter(e => e.매칭상태 == '매칭완료' && e.요청매칭상태 == '매칭완료' && e.배송상태 != '결제대기' && e.배송상태 != '주문취소')

            if(arr.length == 0){
                alert('업로드할 구매내역이 없습니다.')
                return
            }
            // console.log(arr)
            if(this.companyid == 'nomadclub'){
                axios.post('/api/addpurchase/addnomadclubpo',arr)
                .then((res) => {
                    if(res.data.요청결과 == "success"){
                        // console.log(res.data.purchaselist)
                        this.$store.commit('purchaseupdate',res.data.purchaselist);
                        this.$store.commit('productdbupdate',res.data.productdb);
                        setTimeout(() => {
                            this.showlist = false;
                            this.matchlists = [];
                            this.cancel();
                            this.search()
                        },100)

                    } else {
                        alert("구매내역 업데이트 실패 (402)")
                        console.log(res.data)
                        this.cancel();
                    }
                })
                .catch((e) => {alert("구매내역 업데이트 실패 (406)");console.log(e);})
            } else {
                console.log(arr)
                axios.post('/api/addpurchase/addproducts',arr)
                .then((res) => {
                    if(res.data.요청결과 == "success"){
                        // console.log(res.data.purchaselist)
                        this.$store.commit('purchaseupdate',res.data.purchaselist);
                        this.$store.commit('productdbupdate',res.data.productdb);
                        setTimeout(() => {
                            this.showlist = false;
                            this.matchlists = [];
                            this.cancel();
                            this.search()
                        },100)

                    } else {
                        alert("구매내역 업데이트 실패 (402)")
                        console.log(res.data)
                        this.cancel();
                    }
                })
                .catch((e) => {alert("구매내역 업데이트 실패 (406)");console.log(e);})
            }
        },
        searchbutton(){
            if(this.sku검색){
                this.showmatch = false;
                this.showmatchspinner = true;
                var keyword = this.sku검색;
				this.matches = this.$store.state.productdb.filter(e => e['SKU ID'] == keyword);
                setTimeout(() => {
                    this.showmatch = true;
                    this.showmatchspinner = false;
                },10)
                return
            }
            if(this.상품명검색){
                this.showmatch = false;
                this.showmatchspinner = true;
                keyword = this.상품명검색.split(' ');
				this.matches = this.$store.state.productdb.filter(e => keyword.filter(ele => e.상품명 && e.상품명.includes(ele)).length == keyword.length);
                setTimeout(() => {
                    this.showmatch = true;
                    this.showmatchspinner = false;
                },10)
                return
            }
            if(this.옵션명검색){
                this.showmatch = false;
                this.showmatchspinner = true;
                keyword = this.옵션명검색.split(' ')
                var a = this.$store.state.productdb.filter(e => keyword.filter(ele => e.옵션1_중국어 && e.옵션1_중국어.includes(ele)).length == keyword.length)
                var b = this.$store.state.productdb.filter(e => keyword.filter(ele => e.옵션2_중국어 && e.옵션2_중국어.includes(ele)).length == keyword.length)
                this.matches = a.concat(b);
                setTimeout(() => {
                    this.showmatch = true;
                    this.showmatchspinner = false;
                },10)
                return
            }
            alert('검색어를 입력해 주세요')
        },
        searchbuttonin(match,word){
            this.showmatch = false;
            this.showmatchspinner = true;
            this.matches = this.$store.state.productdb.filter(e => e['SKU ID'].includes(word) || e.상품명.includes(word) || e.옵션1_중국어.includes(word) || e.옵션2_중국어.includes(word))
            setTimeout(() => {
                this.showmatch = true;
                this.showmatchspinner = false;
            },10)
        },
        productmatching(a,match){
            match.SKUID = a['SKU ID'];
            var sku = match.SKUID;
            match.매칭상태 = '매칭완료';
            match.상품명 = a.상품명;
            match.바코드 = a.바코드;
            // match.상품링크 = a.구매링크;
            match.등록이미지 = a.등록이미지;
            if(match.요청매칭상태 == '매칭필요'){
                if(this.reqlists.find(e => e.진행현황 == '구매전' && e.SKUID == sku)){
                    if(this.reqlists.filter(e => e.진행현황 == '구매전' && e.SKUID == sku).length == 1){
                        match.요청매칭 = [this.reqlists.filter(e => e.진행현황 == '구매전' && e.SKUID == sku)[0]];
                        match.요청매칭상태 = '매칭완료';
                    } else {
                        match.요청매칭 = this.reqlists.filter(e => e.진행현황 == '구매전' && e.SKUID == sku);
                        match.요청매칭상태 = '매칭필요';
                    }
                } else {
                    if(this.reqlists.find(e => e.진행현황 == '구매전' && e.구매링크.includes(match.상품번호))){
                        if(this.reqlists.find(e => e.진행현황 == '구매전' && e.구매링크.includes(match.상품번호) && e.옵션1_중국어.trim() == match.중국어옵션1.trim() && e.옵션2_중국어.trim() == match.중국어옵션2.trim())){
                            match.요청매칭 = [this.reqlists.find(e => e.진행현황 == '구매전' && e.구매링크.includes(match.상품번호) && e.옵션1_중국어.trim() == match.중국어옵션1.trim() && e.옵션2_중국어.trim() == match.중국어옵션2.trim())];
                            match.요청매칭상태 = '매칭완료';
                        } else {
                            match.요청매칭 = this.reqlists.filter(e => e.진행현황 == '구매전' && e.구매링크.includes(match.상품번호));
                            match.요청매칭상태 = '매칭필요';
                        }
                    }
                }
            }
            this.setinit();
        },
        reqmatching(match){
            if(this.addmatchlist.filter(e => e.checked).length == 0){
                alert('매칭할 요청내역을 체크해 주세요')
                return
            }
            if(this.addmatchlist.filter(e => e.checked).length == 1){
                var matchlist = this.addmatchlist.filter(e => e.checked)
                console.log(matchlist)
                this.searchkeyword = '';
                match.요청매칭상태 = '매칭완료';
                match.요청매칭 = matchlist;
                match.SKUID = matchlist[0].SKUID;
                match.매칭상태 = '매칭완료';
                match.상품명 = matchlist[0].상품명;
                match.바코드 = matchlist[0].바코드;
                match.판매구성수량 = matchlist[0].판매구성수량
                // match.상품링크 = a.구매링크;
                match.등록이미지 = matchlist[0].등록이미지;

                // if(match.주문수량 == a.구매요청수량){
                //     this.matchlists.forEach(e => {
                //         if(e.요청매칭.length > 1){
                //             e.요청매칭 = e.요청매칭.filter(element => element._id !== a._id);
                //         }
                //     })
                // }

                this.setinit();
            }
            if(this.addmatchlist.filter(e => e.checked).length > 1){
                if(confirm('매칭할 요청내역이 2개 이상 선택되었습니다. 모두 추가하시겠습니까?')){
                    matchlist = [this.addmatchlist.filter(e => e.checked)[0]]
                    this.searchkeyword = '';
                    match.요청매칭상태 = '매칭완료';
                    match.요청매칭 = matchlist;
                    match.SKUID = matchlist[0].SKUID;
                    match.매칭상태 = '매칭완료';
                    match.상품명 = matchlist[0].상품명;
                    match.바코드 = matchlist[0].바코드;
                    // match.상품링크 = a.구매링크;
                    match.등록이미지 = matchlist[0].등록이미지;

                    var cnt = this.addmatchlist.filter(e => e.checked).length;
                    for (var j = 1; j < cnt; j++) {
                        matchlist = [this.addmatchlist.filter(e => e.checked)[j]]
                        var obj = this.deepClone(match);
                        obj.요청매칭상태 = '매칭완료';
                        obj.요청매칭 = matchlist;
                        obj.SKUID = matchlist[0].SKUID;
                        obj.매칭상태 = '매칭완료';
                        obj.상품명 = matchlist[0].상품명;
                        obj.바코드 = matchlist[0].바코드;
                        // match.상품링크 = a.구매링크;
                        obj.등록이미지 = matchlist[0].등록이미지;
                        this.matchlists.push(this.deepClone(obj));
                        this.purchaselists.push(this.deepClone(obj));
                    }
                } else {
                    return
                }
            }
            // this.searchkeyword = '';
            // this.temparr = [];
            // match.요청매칭상태 = '매칭완료';
            // match.요청매칭 = [a];
            // match.SKUID = a.SKUID;
            // match.매칭상태 = '매칭완료';
            // match.상품명 = a.상품명;
            // match.바코드 = a.바코드;
            // // match.상품링크 = a.구매링크;
            // match.등록이미지 = a.등록이미지;

            // if(match.주문수량 == a.구매요청수량){
            //     this.matchlists.forEach(e => {
            //         if(e.요청매칭.length > 1){
            //             e.요청매칭 = e.요청매칭.filter(element => element._id !== a._id);
            //         }
            //     })
            // }



            this.setinit();
        },
        insertAndCopy(array, index, cnt) {
            // index와 cnt가 유효한지 확인합니다.
            if (index < 0 || index >= array.length || cnt <= 0) {
                return array; // 유효하지 않은 경우 원래의 배열을 반환합니다.
            }

            var newArray = [];

            for (var i = 0; i < array.length; i++) {
                if (i === index) {
                for (var j = 0; j < cnt; j++) {
                    newArray.push(this.deepClone(array[i]));
                }
                } else {
                    newArray.push(array[i]);
                }
            }

            return newArray;
        },
        setinit(){
            this.showmatch=false;
            this.sku검색='';
            this.상품명검색='';
            this.옵션명검색='';
            this.matches = [];
        },
        matchclass(a){
            if(a == '매칭완료'){
                return 'badge badge-success';
            } else {
                return 'badge badge-warning';
            }
        },
        logisclass(purchase){
            //발송전 : light
            //발송지연 : warning
            //발송완료 : primary
            //배송지연예정 : danger
            //배송지연 : secondary
            //구매확정 : success
            //입고지연 : warning
            if(purchase.배송상태 == '배송전'){
                return 'badge badge-light';
            }
            if(purchase.배송상태 == '발송지연'){
                return 'badge badge-warning';
            }
            if(purchase.배송상태 == '발송완료'){
                return 'badge badge-primary';
            }
            if(purchase.배송상태 == '배송지연예정'){
                return 'badge badge-danger';
            }
            if(purchase.배송상태 == '배송지연'){
                return 'badge badge-secondary';
            }
            if(purchase.배송상태 == '구매확정'){
                return 'badge badge-success';
            }
            if(purchase.배송상태 == '입고지연'){
                return 'badge badge-warning';
            }
            if(purchase.배송상태 == '입고완료'){
                return 'badge badge-success';
            }
            if(purchase.배송상태 == '입고수량오류'){
                return 'badge badge-secondary';
            }
            if(purchase.배송상태 == '입고상품오류'){
                return 'badge badge-warning';
            }
            if(purchase.배송상태 == '배송완료'){
                return 'badge badge-primary';
            }
        },
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },
        datetotimestamp(serial) {
            var utc_days  = Math.floor(serial - 25569);
            var utc_value = utc_days * 86400;
            var date_info = new Date(utc_value * 1000);

            var fractional_day = serial - Math.floor(serial) + 0.0000001;

            var total_seconds = Math.floor(86400 * fractional_day);

            var seconds = total_seconds % 60;

            total_seconds -= seconds;

            var hours = Math.floor(total_seconds / (60 * 60));
            var minutes = Math.floor(total_seconds / 60) % 60;

            return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds).getTime();
        },
        focusrate(){
            setTimeout(()=>{this.$refs.rate.focus();},500)
        },
        async uploadfile(event) {  // ----------------------------------------------------->수정중
            if(this.환율 == 0){
                alert('적용환율을 수정해 주세요') ;
                this.$refs.rate.focus();
                return
            }
            this.showspinner = true;
            this.showmatchlist = false;
            var arr = [];
            var obj = {};
            var 주문번호,판매처,배송단가,할인단가,운송장번호,주문날짜,주문상태,companyid;
            const file = event.target.files[0];
            // var filename = file.name;
            let reader = new FileReader();
            // var content_pdbuploadstatus
            // this.pdbuploadstatus = "업로드 중 ...... (업로드가 완료될 때까지 기다려 주세요)";
            reader.onload = async (e) => {
                // let data = reader.result;
                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary'});
                workbook.SheetNames.forEach(async sheetName => {
                    const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                    console.log(roa)
                    for(var j=0;j<roa.length;j++){
                        var el = roa[j];
                        if(el['货品标题'].split(':').length == 3){
                            var op1 = el['货品标题'].split(':')[1].split(' ').slice(0,el['货品标题'].split(':')[1].split(' ').length-1).join(' ').trim();
                            var op2 = el['货品标题'].split(':')[2].trim();
                        } else {
                            if(el['货品标题'].split(':').length == 2){
                                op1 = el['货品标题'].split(':')[1].trim();
                                op2 = '';
                            } else {
                                if(el['货品标题'].split(':').length == 1){
                                    op1 = '';
                                    op2 = '';
                                } else {
                                    if(el['货品标题'].split(':').length == 4){
                                        op1 = el['货品标题'].split(':')[1].split(' ').slice(0,el['货品标题'].split(':')[1].split(' ').length-1).join(' ').trim();
                                        op2 = (el['货品标题'].split(':')[2] + ":" + el['货品标题'].split(':')[3]);
                                    } else {
                                        alert('관리자에 확인하세요 (244)')
                                    }
                                }
                            }
                        }

                        if(el['订单编号']){
                           주문번호 = el['订单编号'];
                           판매처 = el['卖家公司名'];
                           주문상태 = el['订单状态'];
                           배송단가 = el['运费(元)'] / (roa.filter(element => element['订单编号'] == el['订单编号']).reduce((a,b) => a + b['数量'],0)) ;
                           할인단가 = el['涨价或折扣(元)'] / (roa.filter(element => element['订单编号'] == el['订单编号']).reduce((a,b) => a + b['数量'],0)) ;
                           주문날짜 = this.datetotimestamp(el["订单付款时间"]);
                           운송장번호 = el['运单号'];
                           companyid = el['下游订单号'].split('-')[0];

                        } else {
                            배송단가 = 0;
                            할인단가 = 0;
                        }

                        if(!this.reqlists.find(e => e.진행현황 == '구매전' && e.구매링크.includes(el["Offer ID"]))){
                            var sku = '';
                        } else {
                            if(!op1){
                                if(this.reqlists.filter(e => e.진행현황 == '구매전' && e.구매링크.includes(el["Offer ID"])).length == 1){
                                    sku = this.reqlists.find(e => e.진행현황 == '구매전' && e.구매링크.includes(el["Offer ID"])).SKUID;
                                } else {
                                    sku = '';
                                }
                                //옵션없음

                            } else {
                                if(!op2){
                                    //옵션1만
                                    if(this.reqlists.filter(e => e.진행현황 == '구매전' && e.구매링크.includes(el["Offer ID"]) && e.옵션1_중국어 && op1.trim() == e.옵션1_중국어.trim()).length == 1){
                                        sku = this.reqlists.find(e => e.진행현황 == '구매전' && e.구매링크.includes(el["Offer ID"]) && e.옵션1_중국어 && op1.trim() == e.옵션1_중국어.trim()).SKUID;
                                    } else {
                                        sku = ''
                                    }
                                } else {
                                    //옵션2만
                                    console.log(el)
                                    console.log(op1)
                                    console.log(op2)
                                    // console.log(this.reqlists.filter(e => e.구매링크 && e.구매링크.includes(el["Offer ID"])))
                                    // console.log(this.reqlists.find(e => e.진행현황 == '구매전' && e.구매링크 && e.구매링크.includes(el["Offer ID"]) && e.옵션1_중국어 && e.옵션2_중국어 && op1 == e.옵션1_중국어 && op2.trim() == e.옵션2_중국어.trim()))
                                    if(this.reqlists.filter(e => e.진행현황 == '구매전' && e.구매링크.includes(el["Offer ID"]) && e.옵션1_중국어 && e.옵션2_중국어 && op1.trim() == e.옵션1_중국어.toString().trim() && op2.trim() == e.옵션2_중국어.toString().trim()).length == 1){
                                        sku = this.reqlists.find(e => e.진행현황 == '구매전' && e.구매링크.includes(el["Offer ID"]) && e.옵션1_중국어 && e.옵션2_중국어 && op1.trim() == e.옵션1_중국어.toString().trim() && op2.trim() == e.옵션2_중국어.toString().trim()).SKUID;
                                    } else {
                                        sku = ''
                                    }
                                }
                            }
                        }



                        // if(op2){
                        //     if(this.reqlists.filter(e => e.진행현황 == '구매전' && e.옵션1_중국어 ? ))
                        //     if(this.$store.state.productdb.find(e => (e.옵션1_중국어 ? e.옵션1_중국어?.trim() : e.옵션1_중국어) == (op1 ? op1?.trim() : op1) && (e.옵션2_중국어 ? e.옵션2_중국어?.trim() : e.옵션2_중국어) == (op2 ? op2?.trim() : op2) && e.구매링크 && e.구매링크.includes(el["Offer ID"]))){
                        //         // console.log(el)
                        //         // console.log(this.$store.state.productdb.filter(e => e.옵션1_중국어 == op1 && e.옵션2_중국어 == op2 == op2 && e.구매링크.includes(el["Offer ID"])))
                        //         // console.log(this.$store.state.productdb.find(e => e.옵션1_중국어 == op1 && e.옵션2_중국어 == op2 && e.구매링크.includes(el["Offer ID"])))
                        //         if(this.$store.state.productdb.find(e => (e.옵션1_중국어 ? e.옵션1_중국어?.trim() : e.옵션1_중국어) == (op1 ? op1?.trim() : op1) && (e.옵션2_중국어 ? e.옵션2_중국어?.trim() : e.옵션2_중국어) == (op2 ? op2?.trim() : op2) && e.구매링크 && e.구매링크.includes(el["Offer ID"])).length > 1){
                        //             if(el['SKU ID'] == "4930141588467"){
                        //                 console.log(el)
                        //             }
                        //             var sku = '';
                        //         } else {
                        //             sku = this.$store.state.productdb.find(e => (e.옵션1_중국어 ? e.옵션1_중국어?.trim() : e.옵션1_중국어) == (op1 ? op1?.trim() : op1) && (e.옵션2_중국어 ? e.옵션2_중국어?.trim() : e.옵션2_중국어) == (op2 ? op2?.trim() : op2) && e.구매링크 && e.구매링크.includes(el["Offer ID"]))['SKU ID'];
                        //         }
                        //     } else {
                        //         if(el['SKU ID'] == "4930141588467"){
                        //             console.log(el)
                        //         }
                        //         sku = '';
                        //     }
                        // } else {
                        //     if(this.$store.state.productdb.filter(e => (e.옵션1_중국어 ? e.옵션1_중국어?.trim() : e.옵션1_중국어) == (op1 ? op1?.trim() : op1) && e.구매링크 && e.구매링크.includes(el["Offer ID"])).length > 0){
                        //         // console.log(el)
                        //         // console.log(this.$store.state.productdb.filter(e => e.옵션1_중국어 == op1 && e.구매링크.includes(el["Offer ID"])))
                        //         // console.log(this.$store.state.productdb.find(e => e.옵션1_중국어 == op1 && e.구매링크.includes(el["Offer ID"])))
                        //         if(this.$store.state.productdb.filter(e => (e.옵션1_중국어 ? e.옵션1_중국어?.trim() : e.옵션1_중국어) == (op1 ? op1?.trim() : op1) && e.구매링크 && e.구매링크.includes(el["Offer ID"])).length > 1){
                        //             sku = '';
                        //         } else {
                        //             sku = this.$store.state.productdb.find(e => (e.옵션1_중국어 ? e.옵션1_중국어?.trim() : e.옵션1_중국어) == (op1 ? op1?.trim() : op1) && e.구매링크 && e.구매링크.includes(el["Offer ID"]))['SKU ID'];
                        //         }
                        //     } else {
                        //         sku = '';
                        //     }
                        // }

                        if(this.업체명 !== companyid){
                            alert('업로드한 파일의 "下游订单号"열에서 "-"앞부분이 현재 Connect된 업체ID와 동일해야 합니다. 해당부분을 확인 후에 다시 시도해 주세요')
                            return
                        }
                        obj = {
                            주문날짜: 주문날짜,
                            주문상태: 주문상태,
                            상품번호: el["Offer ID"],
                            판매처: 판매처,
                            중국어옵션1: op1,
                            중국어옵션2: op2,
                            주문수량:el['数量'],
                            주문번호:주문번호,
                            운송장번호:운송장번호,
                            구매단가:el['单价(元)'],
                            배송단가:배송단가,
                            할인단가:할인단가,
                            적용환율:this.환율,
                            구매sku:el['SKU ID'],
                            SKUID:sku,
                            companyid:companyid,
                        }
                        arr.push(obj);
                    }
                    console.log(arr)
                    // var lgnum = '';
                    var lgcost = 0
                    var discnt = 0
                    var ob = {}
                    var addpurchase = []
                    var reqcheckdates = this.reqdates.filter(f => f.checked).map(f => f.date)
                    var req = this.reqlists.filter(f => f.진행현황 === "구매확인" && reqcheckdates.includes(this.getdate(f.요청일)));
                    console.log(req)
                    for(var i=0;i<arr.length;i++){
                        var ele = arr[i];
                        if(ele.배송단가 > 0){
                            ele.배송단가 = ele.배송단가 / (arr.filter(element => element.주문번호 == ele.주문번호).reduce((a,b) => a + b.주문수량,0)) ;
                            ele.할인단가 = ele.할인단가 / (arr.filter(element => element.주문번호 == ele.주문번호).reduce((a,b) => a + b.주문수량,0)) ;
                            lgcost = ele.배송단가
                            discnt = ele.할인단가
                        } else {
                            ele.배송단가 = lgcost;
                            ele.할인단가 = discnt;
                        }

                        if(ele.주문상태 == '交易关闭'){
                            var postatus = '주문취소'
                            var 배송기간 = 0
                        } else {
                            if(ele.주문상태 == '等待买家付款'){
                                postatus = '결제대기'
                                배송기간 = 0
                            } else {
                                if(ele.주문상태 == '等待卖家发货'){
                                    배송기간 = Math.ceil((Date.now()-ele.주문날짜)/(1000*60*60*24))
                                    if(배송기간 < 2){
                                        postatus = '발송전'
                                    } else {
                                        postatus = '발송지연'
                                    }
                                } else {
                                    if(ele.주문상태 == '等待买家确认收货'){
                                        배송기간 = Math.ceil((Date.now()-ele.주문날짜)/(1000*60*60*24))
                                        if(배송기간 < 3){
                                            postatus = '발송완료';
                                        } else {
                                            if(배송기간 < 4){
                                                postatus = '배송지연예정';
                                            } else {
                                                postatus = '배송지연';
                                            }
                                        }
                                    } else {
                                        if(ele.주문상태 == '交易成功'){
                                            배송기간 = Math.ceil((Date.now()-ele.주문날짜)/(1000*60*60*24))
                                            if(배송기간 < 6){
                                                postatus = '구매확정'
                                            } else {
                                                postatus = '입고지연'
                                            }
                                        } else {
                                            alert('[error]관리자에 문의하세요 - ' + ele.주문상태)
                                        }
                                    }
                                }
                            }
                        }

                        console.log('ele', ele)
                        console.log('req',req)
                        var pl = req.filter(f =>
                        f.구매sku === ele.구매sku ||
                        (f.구매링크.includes(ele.상품번호)
                        && ((f.옵션1_중국어 ? f.옵션1_중국어.toString().trim() : '') === (ele.중국어옵션1 ? ele.중국어옵션1.toString().trim() : ''))
                        && ((f.옵션2_중국어 ? f.옵션2_중국어.toString().trim() : '') === (ele.중국어옵션2 ? ele.중국어옵션2.toString().trim() : '')))
                        );
                        console.log('pl.length', pl.length)
                        console.log('pl', pl)
                        if(pl.length == 1){
                            var 총주문수량 = ele.주문수량;
                            var 총구매요청수량 = pl.reduce((pv,f) => {return pv + (parseInt(f.구매요청수량)*parseInt(f.판매구성수량))},0)
                            var 상품매칭 = pl;
                            var 상품링크 = pl[0].구매링크;
                            var 등록이미지 = pl[0].등록이미지;
                            var 상품명 = pl[0].상품명;
                            sku = pl[0].SKUID;
                            var 바코드 = pl[0].바코드;
                            var 판매구성수량 = pl[0].판매구성수량;
                            var 매칭상태 = '매칭완료';

                            var 요청매칭상태 = '매칭완료';
                            var 요청매칭 = pl;

                            ob = {
                                companyid:ele.companyid,
                                등록이미지:등록이미지 ? 등록이미지 : '',
                                상품명:상품명,
                                바코드:바코드,
                                판매구성수량:판매구성수량,
                                배송상태:postatus,
                                배송기간:배송기간,
                                주문날짜:ele.주문날짜,
                                매칭상태:매칭상태,
                                상품매칭:상품매칭,
                                요청매칭상태:요청매칭상태,
                                요청매칭:요청매칭,
                                SKUID:sku,
                                구매sku:ele.구매sku,
                                주문수량:ele.주문수량,
                                판매처:ele.판매처,
                                상품번호:ele.상품번호,
                                상품링크:상품링크,
                                중국어옵션1:ele.중국어옵션1,
                                중국어옵션2:ele.중국어옵션2,
                                주문번호:ele.주문번호,
                                운송장번호:ele.운송장번호,
                                총구매단가: (ele.구매단가+ele.배송단가+ele.할인단가)*this.환율,
                                구매단가:ele.구매단가,
                                배송단가:ele.배송단가,
                                할인단가:ele.할인단가,
                                적용환율:this.환율,
                                site:'1688',
                            }
                            addpurchase.push(ob)
                            this.purchaselists.push(ob)
                            if(총주문수량 == 총구매요청수량){
                                req = req.filter(element => element._id !== pl[0]._id);
                            }
                        }
                        if(pl.length == 0){
                            if(req.filter(f => f.구매링크.includes(ele.상품번호)).length > 0){
                                var pl2 = req.filter(f => f.구매링크.includes(ele.상품번호));
                                상품매칭 = pl2;
                                상품링크 = pl2[0].구매링크;
                                등록이미지 = '';
                                상품명 = '';
                                sku = '';
                                바코드 = '';
                                판매구성수량 = '';
                                매칭상태 = '매칭필요';

                                요청매칭상태 = '매칭필요';
                                요청매칭 = pl2;

                                ob = {
                                    companyid:ele.companyid,
                                    등록이미지:등록이미지 ? 등록이미지 : '',
                                    상품명:상품명,
                                    바코드:바코드,
                                    판매구성수량:판매구성수량,
                                    배송상태:postatus,
                                    배송기간:배송기간,
                                    주문날짜:ele.주문날짜,
                                    매칭상태:매칭상태,
                                    상품매칭:상품매칭,
                                    요청매칭상태:요청매칭상태,
                                    요청매칭:요청매칭,
                                    SKUID:sku,
                                    구매sku:ele.구매sku,
                                    주문수량:ele.주문수량,
                                    판매처:ele.판매처,
                                    상품번호:ele.상품번호,
                                    상품링크:상품링크,
                                    중국어옵션1:ele.중국어옵션1,
                                    중국어옵션2:ele.중국어옵션2,
                                    주문번호:ele.주문번호,
                                    운송장번호:ele.운송장번호,
                                    총구매단가: (ele.구매단가+ele.배송단가+ele.할인단가)*this.환율,
                                    구매단가:ele.구매단가,
                                    배송단가:ele.배송단가,
                                    할인단가:ele.할인단가,
                                    적용환율:this.환율,
                                    site:'1688',
                                }
                                addpurchase.push(ob)
                                this.purchaselists.push(ob)
                            } else {
                                상품매칭 = [];
                                상품링크 = '';
                                등록이미지 = '';
                                상품명 = '';
                                sku = '';
                                바코드 = '';
                                판매구성수량 = '';
                                매칭상태 = '매칭필요';

                                요청매칭상태 = '매칭필요';
                                요청매칭 = [];

                                ob = {
                                    companyid:ele.companyid,
                                    등록이미지:등록이미지 ? 등록이미지 : '',
                                    상품명:상품명,
                                    바코드:바코드,
                                    판매구성수량:판매구성수량,
                                    배송상태:postatus,
                                    배송기간:배송기간,
                                    주문날짜:ele.주문날짜,
                                    매칭상태:매칭상태,
                                    상품매칭:상품매칭,
                                    요청매칭상태:요청매칭상태,
                                    요청매칭:요청매칭,
                                    SKUID:sku,
                                    구매sku:ele.구매sku,
                                    주문수량:ele.주문수량,
                                    판매처:ele.판매처,
                                    상품번호:ele.상품번호,
                                    상품링크:상품링크,
                                    중국어옵션1:ele.중국어옵션1,
                                    중국어옵션2:ele.중국어옵션2,
                                    주문번호:ele.주문번호,
                                    운송장번호:ele.운송장번호,
                                    총구매단가: (ele.구매단가+ele.배송단가+ele.할인단가)*this.환율,
                                    구매단가:ele.구매단가,
                                    배송단가:ele.배송단가,
                                    할인단가:ele.할인단가,
                                    적용환율:this.환율,
                                    site:'1688',
                                }
                                addpurchase.push(ob)
                                this.purchaselists.push(ob)
                            }
                        }

                        if(pl.length > 1){
                            // 상품매칭 = [pl[0]];
                            // 상품링크 = pl[0].구매링크;
                            // 등록이미지 = pl[0].등록이미지;
                            // 상품명 = pl[0].상품명;
                            // 바코드 = pl[0].바코드;
                            // sku = pl[0].SKUID;
                            // 판매구성수량 = pl[0].판매구성수량;
                            // 매칭상태 = '매칭필요';
                            상품매칭 = pl;
                            상품링크 = '';
                            등록이미지 = '';
                            상품명 = '';
                            바코드 = '';
                            sku = '';
                            판매구성수량 = '';
                            매칭상태 = '매칭필요';

                            if(pl.find(f => parseInt(f.구매요청수량)*parseInt(f.판매구성수량) == ele.주문수량)){
                                var f = pl.find(f => parseInt(f.구매요청수량)*parseInt(f.판매구성수량) == ele.주문수량);
                                요청매칭상태 = '매칭완료'
                                요청매칭 = [f];
                                ob = {
                                    companyid:ele.companyid,
                                    등록이미지:등록이미지 ? 등록이미지 : '',
                                    상품명:상품명,
                                    바코드:바코드,
                                    판매구성수량:판매구성수량,
                                    배송상태:postatus,
                                    배송기간:배송기간,
                                    주문날짜:ele.주문날짜,
                                    매칭상태:매칭상태,
                                    상품매칭:상품매칭,
                                    요청매칭상태:요청매칭상태,
                                    요청매칭:요청매칭,
                                    SKUID:sku,
                                    구매sku:ele.구매sku,
                                    주문수량:parseInt(f.구매요청수량)*parseInt(f.판매구성수량),
                                    판매처:ele.판매처,
                                    상품번호:ele.상품번호,
                                    상품링크:상품링크,
                                    중국어옵션1:ele.중국어옵션1,
                                    중국어옵션2:ele.중국어옵션2,
                                    주문번호:ele.주문번호,
                                    운송장번호:ele.운송장번호,
                                    총구매단가: (ele.구매단가+ele.배송단가+ele.할인단가)*this.환율,
                                    구매단가:ele.구매단가,
                                    배송단가:ele.배송단가,
                                    할인단가:ele.할인단가,
                                    적용환율:this.환율,
                                    site:'1688',
                                }
                                addpurchase.push(ob)
                                this.purchaselists.push(ob)
                                req = req.filter(element => element._id !== f._id);
                            } else {
                                총주문수량 = ele.주문수량;
                                총구매요청수량 = pl.reduce((pv,f) => {return pv + (parseInt(f.구매요청수량)*parseInt(f.판매구성수량))},0)
                                if(총주문수량 == 총구매요청수량){
                                    // 상품매칭 = [pl[0]];
                                    // 상품링크 = pl[0].구매링크;
                                    // 등록이미지 = pl[0].등록이미지;
                                    // 상품명 = pl[0].상품명;
                                    // 바코드 = pl[0].바코드;
                                    // sku = pl[0].SKUID;
                                    // 판매구성수량 = pl[0].판매구성수량;
                                    // 매칭상태 = '매칭완료';
                                    상품매칭 = pl;
                                    상품링크 = '';
                                    등록이미지 = '';
                                    상품명 = '';
                                    바코드 = '';
                                    sku = '';
                                    판매구성수량 = '';
                                    매칭상태 = '매칭필요';
                                    for(var k=0;k<pl.length;k++){
                                        f = pl[k];
                                        요청매칭상태 = '매칭완료'
                                        요청매칭 = [f];
                                        ob = {
                                            companyid:ele.companyid,
                                            등록이미지:등록이미지 ? 등록이미지 : '',
                                            상품명:상품명,
                                            바코드:바코드,
                                            판매구성수량:판매구성수량,
                                            배송상태:postatus,
                                            배송기간:배송기간,
                                            주문날짜:ele.주문날짜,
                                            매칭상태:매칭상태,
                                            상품매칭:상품매칭,
                                            요청매칭상태:요청매칭상태,
                                            요청매칭:요청매칭,
                                            SKUID:sku,
                                            구매sku:ele.구매sku,
                                            주문수량:parseInt(f.구매요청수량)*parseInt(f.판매구성수량),
                                            판매처:ele.판매처,
                                            상품번호:ele.상품번호,
                                            상품링크:상품링크,
                                            중국어옵션1:ele.중국어옵션1,
                                            중국어옵션2:ele.중국어옵션2,
                                            주문번호:ele.주문번호,
                                            운송장번호:ele.운송장번호,
                                            총구매단가: (ele.구매단가+ele.배송단가+ele.할인단가)*this.환율,
                                            구매단가:ele.구매단가,
                                            배송단가:ele.배송단가,
                                            할인단가:ele.할인단가,
                                            적용환율:this.환율,
                                            site:'1688',
                                        }
                                        addpurchase.push(ob)
                                        this.purchaselists.push(ob)
                                        req = req.filter(element => element._id !== f._id);
                                    }
                                } else {
                                    상품매칭 = [pl[0]];
                                    상품링크 = pl[0].구매링크;
                                    등록이미지 = pl[0].등록이미지;
                                    상품명 = pl[0].상품명;
                                    바코드 = pl[0].바코드;
                                    sku = pl[0].SKUID;
                                    판매구성수량 = pl[0].판매구성수량;
                                    매칭상태 = '매칭완료';

                                    요청매칭상태 = '매칭필요'
                                    요청매칭 = pl;
                                    ob = {
                                        companyid:ele.companyid,
                                        등록이미지:등록이미지 ? 등록이미지 : '',
                                        상품명:상품명,
                                        바코드:바코드,
                                        판매구성수량:판매구성수량,
                                        배송상태:postatus,
                                        배송기간:배송기간,
                                        주문날짜:ele.주문날짜,
                                        매칭상태:매칭상태,
                                        상품매칭:상품매칭,
                                        요청매칭상태:요청매칭상태,
                                        요청매칭:요청매칭,
                                        SKUID:sku,
                                        구매sku:ele.구매sku,
                                        주문수량:ele.주문수량,
                                        판매처:ele.판매처,
                                        상품번호:ele.상품번호,
                                        상품링크:상품링크,
                                        중국어옵션1:ele.중국어옵션1,
                                        중국어옵션2:ele.중국어옵션2,
                                        주문번호:ele.주문번호,
                                        운송장번호:ele.운송장번호,
                                        총구매단가: (ele.구매단가+ele.배송단가+ele.할인단가)*this.환율,
                                        구매단가:ele.구매단가,
                                        배송단가:ele.배송단가,
                                        할인단가:ele.할인단가,
                                        적용환율:this.환율,
                                        site:'1688',
                                    }
                                    addpurchase.push(ob)
                                    this.purchaselists.push(ob)
                                }
                            }
                        }




                        if(i == arr.length - 1){
                            // console.log(addpurchase)
                            // console.log(arr)
                            // if(this.$store.state.purchase.length > 0){
                            //     // this.matchlists = addpurchase.slice(0,90)
                            //     // .filter(element => element.매칭상태 == '매칭필요' || element.요청매칭상태 == '매칭필요')
                            //     // .filter(element => element.배송상태 != '결제대기')
                            //     // .filter(element => this.$store.state.purchase.filter(el => el.주문번호 == element.주문번호 && el.상품번호 == element.상품번호 && el.중국어옵션1 == element.중국어옵션1 && el.중국어옵션2 == element.중국어옵션2).length == 0)
                            // } else {

                            //     // this.matchlists = addpurchase.slice(0,90)
                            //     // .filter(element => element.매칭상태 == '매칭필요' || element.요청매칭상태 == '매칭필요')
                            //     // .filter(element => element.배송상태 != '결제대기')
                            // }
                            // addpurchase.sort(function(a,b){
                            //     if (a.요청매칭상태 < b.요청매칭상태){
                            //         return -1;
                            //     }
                            //     if (a.요청매칭상태 > b.요청매칭상태){
                            //         return 1;
                            //     }
                            //     return 0;
                            // })
                            addpurchase.sort(function(a,b){
                                if (a.매칭상태 < b.매칭상태){
                                    return -1;
                                }
                                if (a.매칭상태 > b.매칭상태){
                                    return 1;
                                }
                                return 0;
                            })
                            this.matchlists = addpurchase;
                            console.log('matchlists', this.matchlists)
                            this.showspinner = false;
                            this.showmatchlist = true;
                        }
                    }


                    // console.log(this.$store.state.user.companyid)
                    // roa.forEach(e => {delete e._id});
                    // if (roa[0].companyid == this.$store.state.user.companyid) {
                    //     axios.post('/api/addproduct/addproductdb',roa)
                    //     .then((res) => {
                    //         console.log(res.data)
                    //         if (res.data.등록수 == '0') {
                    //             var no_reg_alarm = "이미 모든 상품이 등록되어 있습니다";
                    //             content_pdbuploadstatus = filename + " 업데이트완료 (" + res.data.등록수 + "/" + res.data.등록요청수 + ")";
                    //             this.pdbuploadstatus = content_pdbuploadstatus;
                    //             alert(no_reg_alarm);
                    //         } else {
                    //             var reg_alarm = "신규등록 상품수 : "  + res.data.등록수 + ", 전체 상품수 : " + res.data.productdb.length;
                    //             content_pdbuploadstatus = filename + " 업데이트완료 (" + res.data.등록수 + "/" + res.data.등록요청수 + ")";
                    //             this.pdbuploadstatus = content_pdbuploadstatus;
                    //             this.$store.commit('productdbupdate', res.data.productdb);
                    //             alert(reg_alarm);
                    //         }
                    //     })
                    //     .catch(console.log);
                    // } else {
                    //     alert("Company ID가 일치하지 않습니다")
                    // }
                });
            };
            reader.readAsBinaryString(file);
            // this.$refs.pdbupload.value = '';
            // this.pdbuploadstatus = content_pdbuploadstatus;
            console.log(this.purchaselists)
        },
        async uploadallfile(event) {
            var arr = [];
            var addpurchase = [];
            var obj = {};
            var 주문번호,판매처,배송단가,할인단가,운송장번호,주문날짜,주문상태;
            const file = event.target.files[0];
            let reader = new FileReader();
            reader.onload = async (e) => {
                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary'});
                workbook.SheetNames.forEach(async sheetName => {
                    const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                    console.log(roa)
                    // console.log(roa)
                    for(var j=0;j<roa.length;j++){
                        var el = roa[j];
                        if(el['货品标题'].split(':').length == 3){
                            var op1 = el['货品标题'].split(':')[1].split(' ').slice(0,el['货品标题'].split(':')[1].split(' ').length-1).join(' ').trim();
                            var op2 = el['货品标题'].split(':')[2].trim();
                        } else {
                            if(el['货品标题'].split(':').length == 2){
                                op1 = el['货品标题'].split(':')[1].trim();
                                op2 = '';
                            } else {
                                if(el['货品标题'].split(':').length == 1){
                                    op1 = '';
                                    op2 = '';
                                } else {
                                    if(el['货品标题'].split(':').length == 4){
                                        op1 = el['货品标题'].split(':')[1].split(' ').slice(0,el['货品标题'].split(':')[1].split(' ').length-1).join(' ').trim();
                                        op2 = (el['货品标题'].split(':')[2] + ":" + el['货品标题'].split(':')[3]);
                                    } else {
                                        alert('관리자에 확인하세요 (244)')
                                    }
                                }
                            }
                        }

                        if(el['订单编号']){
                           주문번호 = el['订单编号'];
                           판매처 = el['卖家公司名'];
                           주문상태 = el['订单状态'];
                           배송단가 = el['运费(元)'] / (roa.filter(element => element['订单编号'] == el['订单编号']).reduce((a,b) => a + b['数量'],0)) ;
                           할인단가 = el['涨价或折扣(元)'] / (roa.filter(element => element['订单编号'] == el['订单编号']).reduce((a,b) => a + b['数量'],0)) ;
                           주문날짜 = this.datetotimestamp(el["订单付款时间"]);
                           운송장번호 = el['运单号'];
                        } else {
                            배송단가 = 0;
                            할인단가 = 0;
                        }

                        obj = {
                            주문날짜: 주문날짜,
                            주문상태: 주문상태,
                            상품번호: el["Offer ID"],
                            판매처: 판매처,
                            중국어옵션1: op1,
                            중국어옵션2: op2,
                            주문수량:el['数量'],
                            주문번호:주문번호,
                            운송장번호:운송장번호,
                            구매단가:el['单价(元)'],
                            배송단가:배송단가,
                            할인단가:할인단가,
                            구매sku:el['SKU ID'],
                        }
                        arr.push(obj);
                    }
                    // console.log(arr)
                    // var lgnum = '';
                    var lgcost = 0
                    var discnt = 0
                    var ob = {}
                    for(var i=0;i<arr.length;i++){
                        var ele = arr[i];
                        if(ele.배송단가 > 0){
                            ele.배송단가 = ele.배송단가 / (arr.filter(element => element.주문번호 == ele.주문번호).reduce((a,b) => a + b.주문수량,0)) ;
                            ele.할인단가 = ele.할인단가 / (arr.filter(element => element.주문번호 == ele.주문번호).reduce((a,b) => a + b.주문수량,0)) ;
                            lgcost = ele.배송단가
                            discnt = ele.할인단가
                        } else {
                            ele.배송단가 = lgcost;
                            ele.할인단가 = discnt;
                        }

                        if(ele.주문상태 == '交易关闭'){
                            var postatus = '주문취소'
                            var 배송기간 = 0
                        } else {
                            if(ele.주문상태 == '等待买家付款'){
                                postatus = '결제대기'
                                배송기간 = 0
                            } else {
                                if(ele.주문상태 == '等待卖家发货'){
                                    배송기간 = Math.ceil((Date.now()-ele.주문날짜)/(1000*60*60*24))
                                    if(배송기간 < 2){
                                        postatus = '발송전'
                                    } else {
                                        postatus = '발송지연'
                                    }
                                } else {
                                    if(ele.주문상태 == '等待买家确认收货'){
                                        배송기간 = Math.ceil((Date.now()-ele.주문날짜)/(1000*60*60*24))
                                        if(배송기간 < 3){
                                            postatus = '발송완료';
                                        } else {
                                            if(배송기간 < 4){
                                                postatus = '배송지연예정';
                                            } else {
                                                postatus = '배송지연';
                                            }
                                        }
                                    } else {
                                        if(ele.주문상태 == '交易成功'){
                                            배송기간 = Math.ceil((Date.now()-ele.주문날짜)/(1000*60*60*24))
                                            if(배송기간 < 6){
                                                postatus = '구매확정'
                                            } else {
                                                postatus = '입고지연'
                                            }
                                        } else {
                                            alert('[error]관리자에 문의하세요 - ' + ele.주문상태)
                                        }
                                    }
                                }
                            }
                        }

                        ob = {
                            배송상태:postatus,
                            배송기간:배송기간,
                            주문번호:ele.주문번호,
                            운송장번호:ele.운송장번호,
                            구매sku: ele.구매sku
                        }
                        addpurchase.push(ob)

                        if(i == arr.length - 1){
                            this.updateallpurchase(addpurchase)
                            console.log(addpurchase)
                        }
                    }
                });
            };
            reader.readAsBinaryString(file);
        },
        async updateallpurchase(arr){
            this.uploadtext = '업로드중...'
            if(arr.length == 0){
                alert('업데이트할 내역이 없습니다.')
                return
            }
            axios.post('/api/updatepurchase',arr)
            .then((res) => {
                alert(res.data.요청결과)
                this.uploadtext = '배송현황 일괄 업데이트'
                console.log(res.data)
            })
            .catch((e) => {alert("구매내역 업데이트 실패 (2635)");console.log(e);this.uploadtext = '배송현황 일괄 업데이트';})
        }
    }
}
</script>
<style>
.my-class .dropdown-menu{
    /* min-height: 400px; */
    max-height: 200px;
    min-width:700px;
    overflow-y: auto;
    z-index: 9999;
    margin-top:130px;
}
.modal-dialog {
    max-width: 90%;
    position: absolute;

    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
